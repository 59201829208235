<template>
    <span :class="[wrapperClasses()]">
        <span />
        <span />
        <span />
    </span>
</template>

<script>

export default {
    name: 'Loader',
    props: {
        theme: {
            type: String,
            required: false,
            default: '',
        },
        size: {
            type: String,
            required: false,
            default: '',
        },
    },
    methods: {
        wrapperClasses() {
            return [
                'loader',
                this.theme ? `loader--${this.theme}` : '',
                this.size ? `loader--${this.size}` : '',
            ].join(' ');
        },
    },
};
</script>
