import comparisonApi from '~@api/product-comparison';
import initialiseTooltip from '~@helpers/tooltips';
import { hasCookie, setCookie } from '~@helpers/cookie';

const compareToolbarButton = document.querySelector('.i-compare-items');
const addToCompareButtons = document.querySelectorAll('.i-add-to-comparison');

let firstComparisonTooltip;
let tooltipIsVisible = false;
let tip;

async function createCompareToolTip() {
    try {
        const template = document.getElementById('product-compare-first');
        firstComparisonTooltip = await initialiseTooltip(compareToolbarButton, {
            trigger: 'manual',
            content: template?.innerHTML,
            interactive: true,
            allowHTML: true,
            maxWidth: 244,
            onShown(instance) {
                instance.popper.querySelector('.i-close-tooltip')?.addEventListener('click', instance.hide);
                setTimeout(() => {
                    instance.hide();
                }, 10000);
            },
        });
    } catch (e) {
        // Ignore
    }
}

/**
 *
 * @param { HTMLElement } btn
 */
function registerCompareButton(btn) {
    btn.addEventListener('click', handleFirstTooltip);
}

if (addToCompareButtons.length > 0) {
    addToCompareButtons.forEach(registerCompareButton);
}

if (compareToolbarButton) {
    const template = document.getElementById('product-compare-empty');
    const emptyOptions = {
        trigger: 'manual',
        content: template?.innerHTML,
        interactive: true,
        allowHTML: true,
        maxWidth: 244,
        onShown(instance) {
            instance.popper.querySelector('.i-close-tooltip')?.addEventListener('click', instance.hide);
            setTimeout(() => {
                instance.hide();
            }, 10000);
        },
        onHidden() {
            tooltipIsVisible = false;
        },
    };

    compareToolbarButton.addEventListener('click', async () => {
        if (tip && tooltipIsVisible) {
            tip.hide();
            return;
        }

        try {
            const { data: count } = await comparisonApi.count();
            if (count > 0) {
                window.location.href = '/compare';
                return;
            }
        } catch (e) {
            // Ignore
        }

        try {
            tip = await initialiseTooltip(compareToolbarButton, emptyOptions);

            if (tip) {
                tip.show();
                tooltipIsVisible = true;
            }
        } catch (e) {
            // Ignore
        }
    });
}

function firstTooltipEventListener() {
    firstComparisonTooltip?.hide();
    compareToolbarButton.removeEventListener('click', firstTooltipEventListener);
}

export default async function handleFirstTooltip() {
    if (hasCookie('compare-set')) {
        return;
    }

    setCookie('compare-set');

    if (!firstComparisonTooltip) {
        await createCompareToolTip();
    }

    firstComparisonTooltip.show();
    compareToolbarButton.addEventListener('click', firstTooltipEventListener);
}
