import overlayMediator from '~@helpers/overlay-mediator';
import passiveIfSupported from '~@helpers/passive-supported';

let backdropElement = null;

export default class Backdrop {
    constructor() {
        this.isVisible = false;
    }

    static show({ fullscreen = false } = {}) {
        if (!backdropElement) {
            return;
        }

        backdropElement.classList.add('is-visible');
        this.isVisible = true;

        if (fullscreen) {
            document.body.classList.add('backdrop--full');
        }
    }

    static hide() {
        if (!backdropElement) {
            return;
        }

        backdropElement.classList.remove('is-visible');
        this.isVisible = false;
        document.body.classList.remove('backdrop--full');
    }

    static visible() {
        return this.isVisible;
    }
}

function clearCallbacks() {
    overlayMediator.executeCallbacks();
}

document.addEventListener('DOMContentLoaded', () => {
    backdropElement = document.querySelector('.i-backdrop');

    if (backdropElement) {
        backdropElement.addEventListener('click', clearCallbacks);
        backdropElement.addEventListener('touchstart', clearCallbacks, passiveIfSupported);
        backdropElement.addEventListener('touchmove', clearCallbacks, passiveIfSupported);
    }
});
