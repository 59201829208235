import Vector2 from './vector2';

/**
 * @param {number} value
 * @param {number} limit
 *
 * @returns {number}
 */
function wrap(value, limit) {
    const remainder = value % limit;

    if (remainder < 0) {
        return limit + remainder;
    }

    return remainder;
}

/**
 * @param {number} value
 * @param {number} limit
 *
 * @returns {number}
 */
function noWrap(value, limit) {
    if (value < 0) {
        return 0;
    }

    if (value >= limit) {
        return limit - 1;
    }

    return value;
}

export default class InputController {
    /**
     * @param {object} viewer
     * @param {object} inputMonitor
     * @param {object} initialPosition
     */
    constructor(viewer, inputMonitor, initialPosition) {
        this.viewer = viewer;
        this.isEnabled = false;
        this.initialPosition = initialPosition;
        this.currentPosition = initialPosition;
        this.activeArea = viewer.activeArea();

        inputMonitor.onMove((difference) => {
            this.moveHandler(difference);
        });

        inputMonitor.onEnd(() => {
            this.endHandler();
        });
    }

    /**
     * @param {Vector2} difference
     */
    moveHandler(difference) {
        if (!this.isEnabled) {
            return;
        }

        this.currentPosition = this.tileAfterMove(new Vector2(
            -Math.floor(difference.x / this.activeArea.col),
            Math.floor(difference.y / this.activeArea.row),
        ));
        this.viewer.show(this.currentPosition.x, this.currentPosition.y);
    }

    endHandler() {
        this.initialPosition = this.currentPosition;
    }

    /**
     * @param {Vector2} difference
     *
     * @returns {Vector2}
     */
    tileAfterMove(difference) {
        const column = wrap(this.initialPosition.x + difference.x, this.viewer.columnLimit);
        const row = noWrap(this.initialPosition.y + difference.y, this.viewer.rowLimit);

        return new Vector2(column, row);
    }

    start() {
        this.isEnabled = true;
    }

    stop() {
        this.isEnabled = false;
    }
}
