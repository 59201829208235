import Vue from 'vue';
import { dispatch } from '~@helpers/tracker';

/**
 * @param {HTMLElement} el
 * @param {string} value
 * @param {string} name
 * @param {object} modifiers
 */
function handleTracking(el, { value = '', modifiers = {} }) {
    if (modifiers.click) {
        el.addEventListener('click', () => {
            dispatch(value, el);
        });

        return;
    }

    if (modifiers.change) {
        el.addEventListener('change', () => {
            Vue.nextTick(() => dispatch(value, el));
        });

        return;
    }

    dispatch(value, el);
}

const trackingDirective = {
    bind: handleTracking,
};

Vue.directive('track', trackingDirective);

export default trackingDirective;
