<template>
    <div
        ref="tabs"
        v-at="'tab-header'"
        class="tabs-header"
    >
        <Carousel
            ref="tabsList"
            v-at="'tabbed-content'"
            element="ul"
            :class="['tabs-list', 'menu', {'carousel--centered': centerCarousel}]"
            type="block"
        >
            <template v-slot:content>
                <CarouselItem
                    v-for="(tab, index) in tabData"
                    :key="tab.route"
                    v-at="`tab-${index}`"
                    :index="index"
                    :class="['tabs-list__item', { 'is-active' : isActiveTab(index) }]"
                >
                    <span
                        ref="tabLink"
                        v-at="tab.route"
                        :data-hm="tab.route"
                        tabindex="0"
                        @click="selectTab($event, tab.route, index)"
                        @keydown.enter="selectTab($event, tab.route, index)"
                    >
                        <IconGlyph
                            v-if="tab.icon"
                            :name="tab.icon"
                        />
                        {{ tab.label }}
                    </span>
                </CarouselItem>
            </template>
        </Carousel>
    </div>
</template>

<script>
import IconGlyph from '~@common/icon/IconGlyph';
import Carousel from '../carousel/Carousel';
import CarouselItem from '../carousel/CarouselItem';

export default {
    name: 'TabsHeader',
    components: {
        IconGlyph,
        Carousel,
        CarouselItem,
    },
    props: {
        tabData: {
            type: Array,
            required: true,
        },
        centerCarousel: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        currentActiveIndex: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            markerActive: false,
            activeTabElement: null,
            tabs: document.querySelectorAll('.tabs-list__item'),
        };
    },
    mounted() {
        this.tabs = document.querySelectorAll('.tabs-list__item');
        this.activeTabElement = this.tabs[this.currentActiveIndex];
    },
    updated() {
        this.activeTabElement = this.tabs[this.currentActiveIndex];
    },
    methods: {
        selectTab(e, value, index) {
            this.activeTabElement = e.target.parentNode;
            this.markerActive = true;
            this.$emit('selectTab', { value, index });
        },
        isActiveTab(index) {
            return this.currentActiveIndex === index;
        },
        resetTabs() {
            const e = {
                target: this.$refs.tabLink[0],
            };
            this.selectTab(e, this.tabData[0].route, 0);
        },
    },
};
</script>

<style scoped>
.tabs-header {
    position: relative;
    background-color: var(--charcoal-10);
    text-align: center;
    overflow: hidden;
}

.tabs-list__item {
    display: block;
    padding: 0 16px;
    cursor: pointer;
    text-align: center;
    min-height: 44px;
    line-height: 44px;
    transition: background 0.3s ease-in-out;
    border-bottom: 4px solid transparent;

    &:hover,
    &:focus {
        background-color: var(--charcoal-20);
        outline: none;
    }
}

.tabs-list__item.is-active {
    color: var(--pink-100);
    font-weight: var(--font-weight-heavy);
}

.tabs-list__item span {
    display: block;
    outline: none;
    padding: 0 16px;
}

.tabs-list__item .icon-glyph {
    display: inline;
    vertical-align: text-top;
    margin-right: 4px;
}

.tabs-list__item.is-active .icon-glyph {
    fill: var(--pink-100);
}

.marker {
    transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
    display: block;
    background: var(--pink-100);
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-base);
}
</style>
