/* eslint-disable */

// Feature detection for passive option on addEventListener
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
let passiveIfSupported = false;

try {
    window.addEventListener("test", null,
        Object.defineProperty(
            {},
            "passive",
            {
                get: function() { passiveIfSupported = { passive: true }; }
            }
        )
    );
} catch(err) {}

export default passiveIfSupported;

/* eslint-enable */
