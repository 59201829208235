import dl from '~@tracking/data-layer';

/**
 * Not fully supported in IE/IOS, so use the window object if the event target fails.
 *
 * @returns {any} tracker
 */
function createTarget() {
    try {
        const tracker = new EventTarget();
        return tracker;
    } catch (e) {
        return window;
    }
}

const tracker = createTarget();
const elements = new Map();

// Use the Map to create a reference to any trackable elements in the page
// Only needed for Vanilla DOM elements as Vue will use the v-track directive
// to collect elements to be tracked
[...document.querySelectorAll('[data-track]')].forEach((el) => {
    const [name, event] = el.dataset.track.split(':').reverse();

    // Allow for multiple elements to have the same tracking event
    if (!elements.has(name)) {
        elements.set(name, []);
    }

    elements.get(name).push({ name, event, el });
});

/**
 *
 * @param {string} data
 * @returns {{}|any}
 */
function parseFieldsObject(data) {
    try {
        return JSON.parse(data);
    } catch (e) {
        return {};
    }
}

/**
 *
 * @param {string} name
 * @param {HTMLElement} el
 */
function dispatch(name, el) {
    tracker.dispatchEvent(new CustomEvent(name, {
        detail: {
            category: el.dataset.trackingCategory,
            action: el.dataset.trackingAction,
            label: el.dataset.trackingLabel,
            fieldsObject: parseFieldsObject(el.dataset.trackingFields),
        },
    }));
}

/**
 *
 * @param {object} data
 * @param {object} detail
 * @returns {object}
 */
function mergeData(data, detail) {
    return {
        category: data.category || detail.category || '',
        action: data.action || detail.action || '',
        label: data.label || detail.label || '',
        fieldsObject: data.fieldsObject || detail.fieldsObject || {},
    };
}

/**
 * Set up the event listener to handle the callbacks for the tracking
 *
 * @param {object} config
 */
function trackEvent(config = {}) {
    tracker.addEventListener(config.name, (evt) => {
        const { detail } = evt;

        // Allow values to be functions.
        // We sometimes need to handle dynamic values
        // Assign the return value of the function to the key
        // and discard the function from the config so the value will be correct at runtime.
        Object.keys(config).forEach((key) => {
            if (config[key] instanceof Function) {
                detail[key] = config[key]();
                config[key] = null;
            }
        });

        dl.addTrackingEvent(mergeData(config, detail));
    });
}

/**
 * Register any tracking items and bind them to the DOM Elements in the elements Map()
 *
 * @param {Array} trackingItems
 */
function registerEvents(trackingItems = []) {
    trackingItems.forEach((item) => {
        if (elements.has(item.name)) {
            // Multiple elements may have the same tracking
            elements.get(item.name).forEach((element) => {
                if (element.event) {
                    // Only dispatch when the event occurs on the element
                    element.el.addEventListener(element.event, () => dispatch(item.name, element.el));
                } else {
                    // Immediate dispatch, as no event is provided
                    dispatch(item.name, element.el);
                }
            });
        }
    });
}

export {
    trackEvent,
    registerEvents,
    dispatch,
};
