import dl from '~@tracking/data-layer';

export default {
    /**
     * @param {string} list
     * @param {string} csi
     */
    add(list, csi) {
        dl.addTrackingEvent({
            category: 'favourites',
            action: 'add',
            label: list,
        });

        dl.addTrackingEvent({
            category: 'favourites',
            action: 'add_csi',
            label: csi,
        });
    },
    /**
     * @param {string} list
     * @param {string} csi
     */
    remove(list, csi) {
        dl.addTrackingEvent({
            category: 'favourites',
            action: 'remove',
            label: list,
        });

        dl.addTrackingEvent({
            category: 'favourites',
            action: 'remove_csi',
            label: csi,
        });
    },
    login(label = '') {
        dl.addTrackingEvent({
            category: 'favourites',
            action: 'login',
            label,
        });
    },
    createAccount(label = '') {
        dl.addTrackingEvent({
            category: 'favourites',
            action: 'create_account',
            label,
        });
    },
};
