export default {
    state: {
        query: '',
        searchFocus: false,
    },
    mutations: {
        searchQuery(state, query) {
            state.query = query;
        },
        searchFocus(state, focus) {
            state.searchFocus = focus;
        },
    },
    actions: {
        setSearchQuery({ commit }, query) {
            commit('searchQuery', query);
        },
        setSearchFocus({ commit }, focus) {
            commit('searchFocus', focus);
        },
    },
};
