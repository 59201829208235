import debounce from 'lodash/debounce';
/**
 *
 * @param {HTMLElement} carousel
 */
export default function Carousel(carousel) {
    const container = carousel.querySelector('.carousel__container');
    const content = carousel.querySelector('.carousel__content');
    const items = carousel.querySelectorAll('.carousel__item');
    const controls = [...carousel.querySelectorAll('.carousel__control')];

    if (!('CSS' in window && CSS.supports('width', 'min-content'))) {
        content.style.width = `${[...items].reduce((acc, item) => acc + item.offsetWidth, 1)}px`;
    }

    /**
     *
     * @param {HTMLElement} element
     * @param {string} direction
     * @returns {boolean}
     */
    function isVisible(element, direction) {
        const eb = element.getBoundingClientRect();
        const pb = container.getBoundingClientRect();

        return (direction === 'left') ? eb.left - pb.left < 0 : ((eb.left - pb.left) + eb.width) > pb.width;
    }

    /**
     *
     * @param {string} direction
     * @returns {*}
     */
    function findTargetElement(direction) {
        const visible = [...items].filter(item => isVisible(item, direction));

        return (direction === 'left') ? visible.pop() : visible[0];
    }

    /**
     *
     * @param {HTMLElement} target
     * @param {string} direction
     * @returns {number}
     */
    function distanceToScroll(target, direction) {
        const eb = target.getBoundingClientRect();
        const pb = container.getBoundingClientRect();

        return (direction === 'left') ? (pb.left + pb.width) - (eb.left + eb.width) : eb.left - pb.left;
    }

    /**
     *
     * @param {string} direction
     */
    function handleScroll(direction) {
        const start = container.scrollLeft;
        const target = findTargetElement(direction);

        if (!target) {
            return;
        }

        const distance = distanceToScroll(target, direction);
        const speed = calculateSpeed(1000, distance);

        if (target && direction) {
            animate((progress) => {
                if (direction === 'left') {
                    container.scrollLeft = start - (progress * distance);
                } else {
                    container.scrollLeft = start + (progress * distance);
                }
            }, speed);
        }
    }

    function setStatus() {
        carousel.classList.remove('at-start');
        carousel.classList.remove('at-end');
        carousel.classList.remove('is-overflow');

        if (carousel.scrollWidth < content.scrollWidth) {
            carousel.classList.add('is-overflow');
        }

        if (container.scrollLeft === 0) {
            carousel.classList.add('at-start');
        }

        if ((container.scrollLeft + container.offsetWidth) >= container.scrollWidth) {
            carousel.classList.add('at-end');
        }
    }

    /**
     *
     * @param {number} duration
     * @param {string} distance
     * @returns {number}
     */
    function calculateSpeed(duration, distance) {
        return distance * (duration / 1000);
    }

    /**
     *
     * @param {Function} scroll
     * @param {number} duration
     */
    function animate(scroll, duration) {
        const start = performance.now();

        requestAnimationFrame(function innerAnimate(time) {
            let progress = (time - start) / duration;
            if (progress > 1) {
                progress = 1;
            }

            scroll(progress);

            if (progress < 1) {
                requestAnimationFrame(innerAnimate);
            } else {
                setStatus();
            }
        });
    }

    setStatus();
    window.addEventListener('resize', debounce(() => {
        setStatus();
    }, 100));

    controls.forEach(control => {
        control.addEventListener('click', () => {
            const { direction } = control.dataset;
            handleScroll(direction);
        });
    });

    // Allow for right aligned starts - eg: breadcrumbs
    if (carousel.classList.contains('align-right')) {
        container.scrollLeft = container.scrollWidth;
        setStatus();
    }
}

const carousels = [...document.querySelectorAll('.i-carousel')];
carousels.forEach(Carousel);

if ('matchMedia' in window) {
    const breakpoints = [];
    ['600', '768', '1280'].forEach((size) => {
        breakpoints.push(window.matchMedia(`(min-width: ${size}px)`));
        breakpoints.push(window.matchMedia(`(max-width: ${size - 1}px)`));
    });

    breakpoints.forEach((mql) => {
        mql.addListener((e) => {
            if (e.matches) {
                carousels.forEach(Carousel);
            }
        });
    });
}
