import validations from '../validation/validations';

export default {
    data() {
        return {
            formValidationErrors: {},
        };
    },
    computed: {
        hasError() {
            return Object.keys(this.formValidationErrors).length > 0;
        },
    },
    methods: {
        setValidationError({ field, rule, message }) {
            if (!(field in this.formValidationErrors)) {
                this.formValidationErrors = {
                    ...this.formValidationErrors,
                    [field]: [],
                };
            }

            this.formValidationErrors[field].push({ rule, message });
        },
        clearValidationErrors(fields) {
            if (Array.isArray(fields)) {
                fields.forEach((field) => {
                    this.clearValidationError(field);
                });
            } else {
                this.$nextTick(() => {
                    this.formValidationErrors = {};
                });
            }
        },
        clearValidationError(field) {
            const tmp = this.formValidationErrors;
            delete tmp[field];

            this.formValidationErrors = {
                ...tmp,
            };
        },
        mergeValidationErrors(errors) {
            this.formValidationErrors = {
                ...this.formValidationErrors,
                ...errors,
            };
        },
        validateField({ field, value }) {
            const rules = this.validationRules[field];
            const messages = this.validationMessages[field];

            if (rules && messages) {
                this.clearValidationError(field);

                Object.keys(rules).forEach((rule) => {
                    try {
                        if (!validations[rule](value, rules[rule])) {
                            this.setValidationError({ field, rule, message: messages[rule] });
                            return false;
                        }

                        return true;
                    } catch (e) {
                        return false;
                    }
                });
            }
        },
        hasServerErrors({ errors }) {
            if (!errors) {
                return false;
            }

            return Object.keys(errors).length > 0;
        },
        showServerErrors({ errors }) {
            Object.keys(errors).forEach((key) => {
                this.setValidationError({ field: key, rule: 'server', message: errors[key][0] });
            });
        },
    },
};
