if (window) {
    window.dataLayer = (typeof window.dataLayer === 'undefined') ? [] : window.dataLayer;
}

/**
 * @param {object} event
 */
function addTrackingEvent(event) {
    window.dataLayer.push({
        event: 'gaEvent',
        label: '',
        fieldsObject: {
            nonInteraction: false,
        },
        ...event,
    });
}

/**
 *
 * @param {object} event
 */
function addGa4Event(event) {
    window.dataLayer.push(event);
}

/**
 * Clears the ecommerce data - as recommended by Google
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm
 *
 * @param {object} event
 */
function addEcommerceEvent(event) {
    window.dataLayer.push({ ecommerce: null });
    addGa4Event(event);
}

export default Object.assign(window.dataLayer, {
    addTrackingEvent,
    addGa4Event,
    addEcommerceEvent,
});
