import navigationTracking from '~@tracking/navigation';
import Page from '~@helpers/page-service';

// Breadcrumb tracking
[...document.querySelectorAll('.i-breadcrumb')].forEach((breadcrumb) => {
    breadcrumb.addEventListener('click', () => navigationTracking.breadcrumb(Page.type()));
});

// Usp tracking
[...document.querySelectorAll('.i-usp')].forEach((usp) => {
    usp.addEventListener('click', () => navigationTracking.usp(Page.type(), usp.dataset.trackingLabel));
});
