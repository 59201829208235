/**
 * @returns {string} eventName
 */
function animationEndEvent() {
    const elem = document.createElement('div');

    // Order matters
    const vendorPrefixes = {
        WebkitAnimation: 'webkitAnimationEnd',
        MozAnimation: 'animationend',
        OAnimation: 'oAnimationEnd oanimationend',
        msAnimation: 'MSAnimationEnd',
        animation: 'animationend',
    };

    let eventName = vendorPrefixes.animation;

    Object.keys(vendorPrefixes).forEach((key) => {
        if (elem.style[key] !== undefined) {
            eventName = vendorPrefixes[key];
        }
    });

    return eventName;
}

export default {
    animationEndEvent,
};
