/**
 * Fallback for a simple test of the pathname
 *
 * @param {string} pathName
 * @returns {boolean}
 */
function pathNameFallback(pathName) {
    return window.location.pathname === `/${pathName}`;
}

/**
 * Looks for the victoria plum object that should have the backend results of the page service
 * fallback to a pathname test if this fails.
 *
 */
export default class Page {
    /**
     *
     * @returns {boolean}
     */
    static isCart() {
        if ('victoriaplum' in window) {
            return window.victoriaplum.page.isCart;
        }

        return pathNameFallback('cart');
    }

    /**
     * @returns {Page.isCheckout|boolean}
     */
    static isCheckout() {
        if ('victoriaplum' in window) {
            return window.victoriaplum.page.isCheckout;
        }

        return pathNameFallback('checkout');
    }

    /**
     * @returns {Page.isCheckout|boolean}
     */
    static isHomepage() {
        try {
            return window.victoriaplum.page.type === 'homepage';
        } catch (e) {
            return false;
        }
    }

    /**
     * @returns {string}
     */
    static type() {
        try {
            return window.victoriaplum.page.type;
        } catch (e) {
            return 'undefined';
        }
    }
}
