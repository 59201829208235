import cart from '~@api/cart';
import BasketIcon from './basket-icon';

const basket = document.getElementById('cart-item-icon');
const count = document.getElementById('cart-item-count');

let basketIcon;

/**
 * @param {object} response
 * @returns {Promise}
 */
function processResponse(response) {
    if (response.status !== 200) {
        return Promise.reject(new Error(response.statusText));
    }

    return response.data;
}

/**
 * @param {Array} items
 */
function changeCartItemCount(items) {
    basketIcon.setCount(items.length);
}

function updateHeaderCartCount() {
    cart.fetchItems()
        .then(processResponse)
        .then(changeCartItemCount);
}

if (basket && count) {
    basketIcon = new BasketIcon(basket, count);

    window.addEventListener('item-added-to-basket', updateHeaderCartCount);
    window.addEventListener('item-removed-from-basket', updateHeaderCartCount);

    // @TODO - BAU-1967 unify the cart update events
    window.addEventListener('basket-quantity-updated', updateHeaderCartCount);
    window.addEventListener('cartupdated', updateHeaderCartCount);
}
