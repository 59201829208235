export default {
    methods: {
        addIndex(items) {
            return items.map((item) => ({ UUID: this.generateUUID(), ...item }));
        },
        generateUUID(size = 4) {
            const array = new Uint32Array(size);
            crypto.getRandomValues(array);

            return array.join('-');
        },
        normalise(arr) {
            return arr.map((label) => ({ label, UUID: this.generateUUID() }));
        },
        convert(items) {
            const arr = Object.values(items);
            return this.normalise(arr);
        },
        arrayFormat(items) {
            return items.map((item) => ({ UUID: this.generateUUID(), items: item }));
        },
    },
};
