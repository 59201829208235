import stickyToolbarTracking from '~@tracking/sticky-toolbar';

const stickyToolbar = document.querySelector('.i-sticky-toolbar');

if (stickyToolbar) {
    const links = stickyToolbar.querySelectorAll('.i-sticky-toolbar-link');

    [...links].forEach((link) => {
        link.addEventListener('click', () => {
            stickyToolbarTracking.stickyToolbarLink(link.dataset.label);
        });
    });
}
