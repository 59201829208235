<template>
    <IconGlyph
        v-at="'tooltip-icon'"
        :width="width"
        :height="height"
        name="info"
        class="vue-tooltip"
    />
</template>

<script>
import initialiseTooltip from '~@helpers/tooltips';
import IconGlyph from '~@common/icon/IconGlyph';

export default {
    name: 'Tooltip',
    components: { IconGlyph },
    props: {
        tip: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '16',
        },
        height: {
            type: String,
            default: '16',
        },
        html: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tooltip: null,
        };
    },
    mounted() {
        const options = {
            allowHTML: this.html,
            interactive: this.html,
            content: this.tip,
            onShow: () => this.$emit('tooltipViewed'),
        };

        this.tooltip = initialiseTooltip(this.$el, options);
    },
    updated() {
        this.tooltip = initialiseTooltip(this.$el);
    },
};
</script>

<style scoped>
    .vue-tooltip {
        position: relative;
        transform: translate(7px, 1px);
        fill: var(--pink-100);
    }

    .vue-tooltip::before {
        content: "";
        position: absolute;
        top: -75%;
        left: -75%;
        right: -75%;
        bottom: -75%;
    }
</style>
