import dl from '~@tracking/data-layer';

const category = 'ecommerce';
const action = 'basket reminder';

export default {
    dismissed() {
        dl.addTrackingEvent({
            category,
            action,
            label: 'exit',
        });
    },
    cta(label) {
        dl.addTrackingEvent({
            category,
            action,
            label,
        });
    },
};
