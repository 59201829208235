<template>
    <div class="carousel">
        <component
            :is="containerTag"
            :class="['carousel__container', containerHook]"
        >
            <component
                :is="contentTag"
                :class="['carousel__content', contentHook]"
            >
                <slot name="content" />
                <slot name="marker" />
            </component>
        </component>
        <div class="carousel__controls">
            <button
                class="carousel__control carousel__control--left"
                data-direction="left"
            >
                <IconGlyph name="chevron-left" />
            </button>
            <button
                class="carousel__control carousel__control--right"
                data-direction="right"
            >
                <IconGlyph name="chevron-right" />
            </button>
        </div>
    </div>
</template>

<script>
import Carousel from '~@componentJs/carousel';
import IconGlyph from '~@common/icon/IconGlyph';

export default {
    name: 'Carousel',
    components: {
        IconGlyph,
    },
    props: {
        containerTag: {
            type: String,
            required: false,
            default: () => 'div',
        },
        containerHook: {
            type: String,
            required: false,
            default: () => '',
        },
        contentTag: {
            type: String,
            required: false,
            default: () => 'ul',
        },
        contentHook: {
            type: String,
            required: false,
            default: () => '',
        },
    },
    mounted() {
        this.$nextTick(() => { Carousel(this.$el); });
    },
    updated() {
        this.$nextTick(() => { Carousel(this.$el); });
    },
};
</script>
