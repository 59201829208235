let tippy;

/**
 * @param {HTMLElement} targetElement
 * @param {object} options
 * @returns {*}
 */
export default async function initialiseTooltip(targetElement, options) {
    if (!tippy) {
        ({ default: tippy } = await import(/* webpackChunkName: "lazy-tippy" */ 'tippy.js'));
    }

    const defaults = {
        animation: 'tooltip',
        trigger: 'mouseenter click',
    };

    return tippy(targetElement, { ...defaults, ...options });
}
