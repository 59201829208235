import api from '~@api/favourites';
import favouritesTracking from '~@tracking/favourites';

const headerIconView = document.querySelector('.favourites-icon');
const favouritesLogin = document.querySelector('.i-favourites-login');
const favouritesCreateAccount = document.querySelector('.i-favourites-create-account');

/**
 * Update any favourites icons
 *
 * @param {number} count
 */
export function updateIcon(count) {
    headerIconView.classList[(count > 0) ? 'remove' : 'add']('favourites-icon--empty');
}

/**
 * Handle various updates in the DOM
 *
 * @param {object} response
 */
export function updateView(response) {
    updateIcon(response.data.count);
}

/**
 * @param {NodeList} nodeList
 * @param {string} action
 */
export function attachFavouritesAction(nodeList, action) {
    [...nodeList].forEach((node) => {
        const csi = node.getAttribute('data-csi');
        const csis = node.getAttribute('data-csis');
        const location = node.getAttribute('data-location');

        if (!(csis && ['add', 'remove'].includes(action))) {
            return;
        }

        node.addEventListener('click', function _fnc() {
            if (action === 'add') {
                favouritesTracking.add(location, csi);
            } else {
                favouritesTracking.remove(location, csi);
            }

            node.removeEventListener('click', _fnc);
            node.classList[action]('selected');
            api[action](csis).then(updateView);
            attachFavouritesAction([node], ((action === 'add') ? 'remove' : 'add'));
        });
    });
}

const productDetail = document.querySelector('[data-page="product-detail"]');

// Bind any existing favourites actions expect
// on the product detail page, as this is handled separately
if (!productDetail) {
    attachFavouritesAction(document.querySelectorAll('.i-add-favourite'), 'add');
    attachFavouritesAction(document.querySelectorAll('.i-remove-favourite'), 'remove');
}

if (favouritesLogin && favouritesCreateAccount) {
    // Attach UV tracking events to account login and account creation links
    favouritesLogin.addEventListener('click', () => {
        favouritesTracking.login();
    });

    favouritesCreateAccount.addEventListener('click', favouritesTracking.createAccount);
}
