import ActivatableItem from '~@componentJs/activatable-item';

/**
 * Show and hide content
 */
export default class ContentReveal extends ActivatableItem {
    constructor(container, triggers, isActive) {
        super(container, isActive);
        this.container = container;
        this.view = this.container.querySelector('.i-reveal-view');
        this.content = this.container.querySelector('.i-reveal-content');
        this.before = { open: [], close: [] };
        this.after = { open: [], close: [] };

        [...triggers].forEach((toggle) => {
            toggle.addEventListener('click', this.togglePanel.bind(this));
        });
    }

    /**
     * Works out the height of the content
     * to enable transitions in CSS
     */
    setHeight() {
        if (this.view && this.content) {
            this.view.style.height = `${this.content.offsetHeight}px`;
        }
    }

    /**
     * Clears the height of the content
     * to enable transitions in CSS
     */
    clearHeight() {
        if (this.view) {
            this.view.style.height = null;
        }
    }

    /**
     * Lifecycle hook
     *
     * @param {Function} fnc
     */
    onBeforeOpen(fnc) {
        this.before.open.push(fnc);
    }

    /**
     * Lifecycle hook
     *
     * @param {Function} fnc
     */
    onAfterOpen(fnc) {
        this.after.open.push(fnc);
    }

    /**
     * Lifecycle hook
     *
     * @param {Function} fnc
     */
    onBeforeClose(fnc) {
        this.before.close.push(fnc);
    }

    /**
     * Lifecycle hook
     *
     * @param {Function} fnc
     */
    onAfterClose(fnc) {
        this.after.close.push(fnc);
    }

    /**
     * Expand the panels using CSS transitions
     */
    expand() {
        this.setHeight();
        this.view.classList.add('animating');
        this.view.addEventListener('transitionend', this.removeAnimation.bind(this));
    }

    /**
     * Collapse the panels using CSS transitions
     * allow for race condition on the style attr setting
     */
    collapse() {
        this.setHeight();
        this.view.classList.add('animating');
        this.view.addEventListener('transitionend', this.removeAnimation.bind(this));

        window.requestAnimationFrame(() => {
            if (!this.view) {
                return;
            }

            this.view.classList.remove('active');
            this.view.style.height = '0';
        });
    }

    openPanel() {
        this.before.open.forEach(fnc => fnc());
        this.setActive();
        this.after.open.forEach(fnc => fnc());
    }

    closePanel() {
        this.before.close.forEach(fnc => fnc());
        this.setInactive();
        this.after.close.forEach(fnc => fnc());
    }

    togglePanel() {
        if (this.active) {
            this.closePanel();
        } else {
            this.openPanel();
        }
    }

    removeAnimation() {
        if (!this.view) {
            return false;
        }

        this.view.removeEventListener('transitionend', this.removeAnimation.bind(this));
        this.view.classList.remove('animating');
        this.view.style.height = null;

        return true;
    }
}
