import pageTracking from '~@tracking/page';

const cards = document.querySelectorAll('.i-image-card-list-item');
let pageType;

try {
    pageType = window.victoriaplum.page.type;
} catch (error) {
    pageType = null;
}

[...cards].forEach((elem) => {
    elem.addEventListener('click', () => {
        if (pageType === 'homepage') {
            pageTracking.trackEvent({
                category: 'home page',
                action: 'category_tiles',
                label: elem.getAttribute('data-uv'),
            });
        }
    });
});
