<template>
    <div :class="[wrapperClasses(), {'dismissed': dismissed }]">
        <span
            v-if="includeIcon"
            class="alert__icon"
        >
            <IconGlyph :name="icon()" />
        </span>
        <span class="alert__message">
            <slot />
        </span>
        <span
            v-if="dismissible"
            v-at="'close-alert'"
            class="alert__close"
            @click="dismiss"
        >
            <IconGlyph name="close" />
        </span>
    </div>
</template>

<script>
import IconGlyph from '~@common/icon/IconGlyph';
import vendorPrefix from '~@helpers/vendor-prefix';

const animationEndEvent = vendorPrefix.animationEndEvent();

export default {
    name: 'Alert',
    components: { IconGlyph },
    props: {
        type: {
            type: String,
            required: false,
            default: 'general',
        },
        dismissible: {
            type: Boolean,
            default: true,
        },
        includeIcon: {
            type: Boolean,
            default: true,
        },
        iconStyle: {
            type: String,
            required: false,
            default: () => '',
        },
        iconName: {
            type: String,
            required: false,
            default: () => '',
        },
    },
    data() {
        return {
            defaultIcons: {
                error: 'caution',
                caution: 'caution',
                info: 'info',
                success: 'circle-check',
                general: 'info',
            },
            dismissed: false,
        };
    },
    methods: {
        wrapperClasses() {
            return [
                'alert',
                `alert--${this.type}`,
                this.iconStyle ? `alert--icon-${this.iconStyle}` : '',
                this.dismissible ? 'i-fade-out' : '',
            ].join(' ');
        },
        dismiss() {
            this.$el.addEventListener(animationEndEvent, this.remove);
            this.dismissed = true;
        },
        remove(ev) {
            if (ev.animationName === 'fade-out') {
                this.$el.removeEventListener(animationEndEvent, this.remove);
                this.$emit('dismiss');
            }
        },
        icon() {
            if (this.iconName) {
                return this.iconName;
            }

            return this.defaultIcons[this.type] || this.defaultIcons.general;
        },
    },
};
</script>
