export default class ThreeSixtyViewer {
    /**
     * @param {HTMLImageElement} target
     * @param {string[]} images
     * @param {number} rowLimit
     * @param {number} columnLimit
     */
    constructor(target, images, rowLimit, columnLimit) {
        this.target = target;
        this.images = images;
        this.rowLimit = rowLimit;
        this.columnLimit = columnLimit;

        this.target.draggable = false;
    }

    /**
     * Display the requested image.
     *
     * @param {number} x
     * @param {number} y
     */
    show(x, y) {
        if (y < 0 || y >= this.rowLimit) {
            throw Error(`y out of bounds - must be from 0 and below ${this.rowLimit}`);
        }

        if (x < 0 || x >= this.columnLimit) {
            throw Error(`x out of bounds - must be from 0 and below ${this.columnLimit}`);
        }

        this.target.src = this.images[(this.columnLimit * y) + x];
    }

    /**
     *
     * @returns {{col: number, row: number}}
     */
    activeArea() {
        const areaFactor = 0.6;
        const { width, height } = this.target.getBoundingClientRect();
        const activeColArea = (width * areaFactor) / this.columnLimit;
        const activeRowArea = (height * areaFactor) / this.rowLimit;

        return { col: activeColArea, row: activeRowArea };
    }
}
