import dl from '~@tracking/data-layer';

const category = 'meganav';

export default {
    openMenu() {
        dl.addTrackingEvent({
            category,
            action: 'meganav_open_menu',
        });
    },
    itemLink(link) {
        dl.addTrackingEvent({
            category,
            action: 'meganav_item_link_click',
            label: link,
        });
    },
    subGroup(heading, title) {
        dl.addTrackingEvent({
            category,
            action: 'meganav_item_sub_group',
            label: `${heading} : ${title}`,
        });
    },
    trackCategory(label) {
        dl.addTrackingEvent({
            category,
            action: 'meganav_view_category',
            label,
        });
    },
};
