import Vue from 'vue';
import EmailSignupModal from '../../../vue/component/EmailSignupModal';
import { setCookie, hasCookie, getCookie } from '../helpers/cookie';

const mount = document.querySelector('.i-email-signup-modal');
const cookieName = 'vp-page-view';
const pageViews = getCookie(cookieName);

const data = { modalActive: false };

function dismiss() {
    data.modalActive = false;
}

function showEmailSignupModal() {
    data.modalActive = true;
}

if (!hasCookie(cookieName)) {
    setCookie(cookieName, { value: 1 });
} else if (pageViews === '1') {
    setCookie(cookieName, { value: 2 });
    data.modalActive = true;
}

if (mount) {
    new Vue({
        el: mount,
        components: { EmailSignupModal },
        data() {
            return { data };
        },
        provide() {
            return {
                dismiss,
            };
        },
        template: `<EmailSignupModal
            :active="data.modalActive"
        />`,
    });
}

// Expose method for AB testing
window.victoriaplum.showEmailSignupModal = showEmailSignupModal;
