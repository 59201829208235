import ImgixClient from 'imgix-core-js';

const image = {
    methods: {
        /**
         * Returns the image URL with any formatting options appended as query params
         *
         * This uses the Imgix library to generate image urls.
         *
         * It does not validate the values or parameters passed to the method, so if
         * you're unsure of the correct parameters to use then please refer to
         * the Imgix API docs - https://docs.imgix.com/apis/url.
         *
         * @param {string} path
         * @param {object} options
         * @returns {string}
         */
        hostedImagePath(path, options = {}) {
            const url = this.parsePathIntoURL(path);

            const defaultOptions = {
                auto: 'format,compress',
                q: '55',
            };

            const client = new ImgixClient({
                domain: url.hostname,
                includeLibraryParam: false,
            });

            const urlString = client.buildURL(url.pathname + url.hash, Object.assign(defaultOptions, options));

            return this.sanitiseUrl(urlString);
        },

        parsePathIntoURL(path) {
            try {
                return new URL(this.sanitiseUrl(path));
            } catch (err) {
                const base = window.location.protocol + window.victoriaplum.config.hosted_image_path;
                return new URL(this.sanitiseUrl(path), base);
            }
        },
        /**
         * Removes any extraneous slashes from the url whilst retaining the leading http:// or https://
         *
         * @param {string} url
         * @returns {string}
         */
        sanitiseUrl(url) {
            return url.split(/\/+/)
                .filter(urlSegment => !urlSegment.match(/^\s*$/))
                .join('/')
                .replace(':/', '://');
        },
        /**
         * @param {string} url
         * @param {string }contentType
         * @returns {Promise<Blob>}
         */
        async getImageBlobFromUrl(url, contentType = 'image/jpeg') {
            const response = await fetch(url, { cache: 'force-cache' });
            const data = await response.blob();

            return new Blob([data], {
                type: response.headers.get('content-type') || contentType,
            });
        },
        /**
         * @returns {Promise<void>}
         * @param {Array} images
         */
        convertImages(images) {
            return Promise.all(images.map(async (val) => this.getImageBlobFromUrl(val)));
        },
    },
};

export default image;
