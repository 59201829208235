import Countdown from './countdown';

const countdown = document.querySelector('.i-countdown-timer');
const countdownTimer = new Countdown();

/**
 * showCountdown
 * Promo-bar countdown is initially hidden until countdown is ready
 * to hide the placeholder 00d 00h 00m 00s
 */
function intialiseCountdown() {
    countdown.classList.remove('is-invisible');
}

/**
 * render - render updated time as string e.g. 03d 02h 23m 00s
 *
 * @param {object} timer
 */
function render(timer) {
    let display = `${timer.minutes}m ${timer.seconds}s`;

    if (timer.hours > 0) {
        display = `${timer.hours}h ${display}`;
    }

    if (timer.days > 0) {
        display = `${timer.days}d ${display}`;
    }

    countdown.textContent = display;
}

if (countdown) {
    countdownTimer.start(countdown.getAttribute('datetime'), render);
    intialiseCountdown();
}
