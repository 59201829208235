import dl from '~@tracking/data-layer';

import { trackEvent, registerEvents } from '~@helpers/tracker';

const config = {
    category: 'navigation',
};

const trackingElements = [
    {
        name: 'burger-button',
        action: 'burger nav',
        label: '',
    },
    {
        name: 'help-button',
        action: 'help-icon',
    },
    {
        name: 'account-button',
        action: 'account-icon',
    },
    {
        name: 'favourites-button',
        action: 'favourites-icon',
    },
    {
        name: 'shopping-list-button',
        action: 'shopping-list-icon',
    },
    {
        name: 'cart-button',
        action: 'cart-icon',
        label: '',
    },
    {
        name: 'help-service',
        action: 'help-service',
    },
];

// Vue components use the v-track directive, so we only need to attach the listener
trackingElements.forEach((item) => {
    trackEvent({ ...config, ...item });
});

// Vanilla DOM elements need to be registered via the data-track attribute
registerEvents(trackingElements);

export default {
    /**
     * @param {string} label
     */
    breadcrumb(label) {
        dl.addTrackingEvent({
            category: 'navigation',
            action: 'breadcrumbs',
            label,
        });
    },
    /**
     * @param {string} pageType
     * @param {string} label
     */
    usp(pageType, label) {
        dl.addTrackingEvent({
            category: 'usp',
            action: pageType,
            label,
        });
    },
};
