import ContentReveal from '~@componentJs/content-reveal';
import CustomEventHelper from '~@helpers/custom-event';

[...document.querySelectorAll('.i-faq')].forEach((elem) => {
    const toggles = [...elem.querySelectorAll('.i-toggle-active')];
    const reveal = new ContentReveal(elem, toggles, elem.classList.contains('is-active'));

    reveal.onBeforeOpen(() => {
        reveal.expand();

        if (reveal.element) {
            const event = CustomEventHelper('faqOpened', {
                content: reveal.element?.firstElementChild.innerText,
            });

            window.dispatchEvent(event);
        }
    });

    reveal.onBeforeClose(() => {
        reveal.collapse();
    });
});
