import axios from 'axios';

/**
 * VictoriaPlum.com product comparison
 */
export default {
    /**
     * @param {string} csi
     *
     * @returns {Promise}
     */
    add(csi) {
        return axios.post(`/api/v1/compare/${csi}`);
    },

    /**
     * @param {string} csi
     *
     * @returns {Promise}
     */
    remove(csi) {
        return axios.delete(`/api/v1/compare/${csi}`);
    },

    /**
     * @returns {Promise}
     */
    list() {
        return axios.get('/api/v1/compare');
    },

    /**
     * @returns {Promise}
     */
    count() {
        return axios.get('/api/v1/compare/count');
    },

    /**
     * @returns {Promise}
     */
    csis() {
        return axios.get('/api/v1/compare/csis');
    },
};
