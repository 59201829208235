const variants = {
    box: 'es',
};

/**
 * @param {number} quantity
 * @param {string} text
 * @returns {string}
 */
export default function pluralise(quantity, text) {
    return `${text}${(quantity === 1) ? '' : variants[text] || 's'}`;
}
