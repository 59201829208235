/**
 * Allows an elements state to be set to active/inactive
 */
export default class ActivatableItem {
    /**
     *
     * @param {HTMLElement} DOMElement
     * @param {boolean} active
     */
    constructor(DOMElement, active) {
        if (!(DOMElement instanceof HTMLElement)) {
            throw new Error('DOMElement must be an instance of Element');
        }

        this.element = DOMElement;
        this.isActive = !!active;

        if (this.active) {
            this.setActive();
        }
    }

    setActive() {
        this.element.classList.add('is-active');
        this.isActive = true;
    }

    setInactive() {
        this.element.classList.remove('is-active');
        this.isActive = false;
    }

    /**
     * @returns {boolean}
     */
    get active() {
        return this.isActive;
    }
}
