import ValidationException from './ValidationException';

export default {
    required(value, expected) {
        if (expected === false) {
            return true;
        }

        let valid = false;

        switch (typeof value) {
            case 'number':
                valid = true;
                break;
            case 'string':
                valid = !!(value.trim()) === expected;
                break;
            default:
                valid = false;
        }

        return valid;
    },
    min(value, min) {
        if (typeof min !== 'number') {
            throw new ValidationException(`The validation comparison must be a number not: ${typeof min}`);
        }

        if (!value) {
            return false;
        }

        if (typeof value === 'number') {
            return value >= min;
        }

        return [...value].length >= min;
    },
    max(value, max) {
        if (typeof max !== 'number') {
            throw new ValidationException(`The validation comparison must be a number not: ${typeof max}`);
        }

        if (!value) {
            return true;
        }

        if (typeof value === 'number') {
            return value <= max;
        }

        return [...value].length <= max;
    },
    email(value) {
        // Simple email validation as used in the HTML5 spec by most browser vendors
        // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
        // The backend will validate to our own requirements

        /* eslint-disable */
        const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        /* eslint-enable */
        return regex.test(value);
    },
    containsNumber(value) {
        return /\d/.test(value);
    },
    containsUpperCaseLetter(value) {
        return /(.*[A-Z].*)/.test(value);
    },
    containsSymbolCharacter(value) {
        return /(.*[ !"#$%&'()*+,-.\\/:;<=>?@[\]^_`{|}~].*)/.test(value);
    },
};
