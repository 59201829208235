import Vue from 'vue';
import Vuex from 'vuex';
import searchStore from '~@store/search';
import SearchBar from '../../../vue/component/quick-search/SearchBar';

Vue.use(Vuex);

const searchbarMount = document.querySelector('.i-mount-searchbar');

if (searchbarMount) {
    new Vue({
        el: searchbarMount,
        components: {
            SearchBar,
        },
        store: new Vuex.Store(searchStore),
    });
}
