import Vue from 'vue';
import loginTracking from '~@tracking/login';
import CustomEventHelper from '~@helpers/custom-event';
import PanelEvents from '~@componentJs/off-canvas-panel-events';
import TabsHeader from '~@common/TabsHeader';
import ActivateableCollection from './activatable-collection';
import ActivateableItem from './activatable-item';
import PanelViews from './panel-views';
import offCanvasPanels from './off-canvas-panels';
import Page from '../helpers/page-service';

const shoppingTabs = document.querySelector('.i-panel-tabbed-content-tabs');
const tabsMount = document.getElementById('i-mount-panel-tabbed-content-tabs');
const panel = offCanvasPanels.byId('off-canvas-panel-right');

let shoppingList = null;
const shoppingListMount = document.getElementById('i-mount-shopping-list');

async function showShoppingList() {
    if (shoppingList) {
        return;
    }

    if (shoppingListMount) {
        const { default: module } = await import(
            /* webpackChunkName: "shopping-list" */
            '~@bundles/shopping-list'
        );

        shoppingList = module;
        shoppingList.mount(shoppingListMount);
    }

    if (panel && shoppingList) {
        const panelEvents = new PanelEvents(panel);

        panelEvents.add('close', 'shopping-list', shoppingList.tracking.dismiss);
        shoppingListMount.addEventListener('dismiss', () => panel.close());
    }
}

if (panel) {
    const panelViews = new PanelViews(panel);

    panelViews.onAfterChange((target) => {
        if (target === 'sign-in') {
            loginTracking.showForm(panel.id);
        }
    });

    [...document.querySelectorAll('.i-open-mini-cart')].forEach((elem) => {
        panel.addTrigger(elem);
        panelViews.addTrigger(elem);
        elem.addEventListener('click', () => {
            activateTabByName('Basket');
        });
    });

    [...document.querySelectorAll('.i-close-off-canvas-panel-right')].forEach((elem) => {
        panel.addTrigger(elem);
    });
}

if (panel) {
    const views = new PanelViews(panel);

    [...document.querySelectorAll('.i-show-shopping-list')].forEach((elem) => {
        panel.addTrigger(elem);
        views.addTrigger(elem);
        elem.addEventListener('click', () => { activateTabByName('Shopping List'); });
    });
}

const tabConfig = {
    tabs: [
        {
            label: 'Shopping List',
            route: 'shopping-list',
            icon: 'guide',
        },
        {
            label: 'Basket',
            route: 'basket',
            icon: 'basket',
        },
    ],
    active: 0,
};

if (shoppingTabs) {
    if (tabsMount) {
        const tabbedContent = new ActivateableCollection();

        [...shoppingTabs.querySelectorAll('.i-tab-body')].forEach((node) => {
            tabbedContent.addItem(new ActivateableItem(node, node.classList.contains('is-active')));
        });

        // Adjust for the cart page
        // we want the shopping list tab, but not the mini cart ...
        if (Page.isCart()) {
            tabConfig.tabs = [
                {
                    label: 'Shopping List',
                    route: 'shopping-list',
                    icon: 'guide',
                },
            ];
        }

        new Vue({
            el: tabsMount,
            components: { TabsHeader },
            data() {
                return {
                    tabConfig,
                };
            },
            computed: {
                activeTab() {
                    return tabConfig.active;
                },
            },
            watch: {
                activeTab(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.changeTab(newVal);
                    }
                },
            },
            methods: {
                changeTab(index) {
                    tabbedContent.active().forEach(item => item.setInactive());
                    tabbedContent.all()[this.tabConfig.active].setActive();

                    if (this.tabConfig.tabs[index].route === 'shopping-list') {
                        showShoppingList();
                    }
                },
                onSelectTab({ value, index = 0 } = {}) {
                    if (index === this.activeTab) {
                        return;
                    }

                    if (value === 'basket') {
                        window.dispatchEvent(CustomEventHelper('mount-minicart'));
                    }

                    this.tabConfig.active = index;
                },
            },
            template: '<TabsHeader :tabData="tabConfig.tabs" :centerCarousel="true" :currentActiveIndex="tabConfig.active" @selectTab="onSelectTab" />',
        });
    }
}

/**
 * Switch the tabs by name
 *
 * @param {string} name
 * @returns {boolean}
 */
export function activateTabByName(name) {
    if (name === 'Shopping List') {
        showShoppingList();
    }

    return activateTabByIndex(tabConfig.tabs.findIndex(tab => tab.label === name));
}

/**
 * Switch the tabs by index
 *
 * @param {number} index
 * @returns {boolean}
 */
export function activateTabByIndex(index) {
    if (index >= 0 && tabConfig.tabs[index]) {
        tabConfig.active = index;
        return true;
    }

    return false;
}
