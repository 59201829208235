<template>
    <div
        v-at="'review-stars'"
        :class="starSize"
        class="review-stars"
    >
        <span
            :class="`stars-${fullStars}-${halfStar}`"
            class="stars"
        />
        <span
            v-if="count"
            v-at="`rating-${fullStars}-count`"
            :class="textSize"
            class="text review-star__count"
            itemprop="ratingCount"
        >
            ({{ count }})
        </span>
    </div>
</template>

<script>
export default {
    name: 'ReviewStars',
    props: {
        rating: {
            type: Number,
            required: true,
        },
        count: {
            type: [String, Number],
            default: 0,
        },
        size: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            starSize: 'review-stars--md',
            textSize: 'text--v4',
        };
    },
    computed: {
        fullStars() {
            return Math.floor(this.rating);
        },
        halfStar() {
            return Math.round(this.rating - this.fullStars) === 0 ? 0 : 5;
        },
    },
    mounted() {
        this.setStarSize();
    },
    methods: {
        setStarSize() {
            switch (this.size) {
                case 'small':
                    this.starSize = 'review-stars--sm';
                    this.textSize = 'text--v5';
                    break;
                case 'large':
                    this.starSize = 'review-stars--lg';
                    break;
                default:
                    this.starSize = 'review-stars--md';
            }
        },
    },
};
</script>

<style>
    .review-stars {
        display: inline-flex;
    }

    .stars {
        width: 100px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16' fill='%23E0E0E1'%3E%3Ctitle%3Estar-full%3C/title%3E%3Cpath d='M15.144 5.438l-4.316-.627L8.897.9C8.559.217 7.441.217 7.103.9l-1.93 3.911-4.317.627a1.002 1.002 0 0 0-.554 1.707l3.124 3.044-.737 4.299a1 1 0 0 0 1.451 1.054L8 13.513l3.861 2.03c.728.381 1.591-.234 1.451-1.054l-.737-4.299 3.124-3.044a1.003 1.003 0 0 0-.555-1.708z'/%3E%3C/svg%3E");
    }

    .stars::after {
        content: '';
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' viewBox='0 0 20 16' fill='%23FFB319'%3E%3Ctitle%3Estar-full%3C/title%3E%3Cpath d='M15.144 5.438l-4.316-.627L8.897.9C8.559.217 7.441.217 7.103.9l-1.93 3.911-4.317.627a1.002 1.002 0 0 0-.554 1.707l3.124 3.044-.737 4.299a1 1 0 0 0 1.451 1.054L8 13.513l3.861 2.03c.728.381 1.591-.234 1.451-1.054l-.737-4.299 3.124-3.044a1.003 1.003 0 0 0-.555-1.708z'/%3E%3C/svg%3E");
    }

    .stars,
    .stars::after {
        display: block;
        height: 16px;
        background-size: 20px 16px;
        background-repeat: repeat-x;
    }

    .stars-5-0::after {
        width: 100px;
    }

    .stars-4-5::after {
        width: 88px;
    }

    .stars-4-0::after {
        width: 80px;
    }

    .stars-3-5::after {
        width: 68px;
    }

    .stars-3-0::after {
        width: 60px;
    }

    .stars-2-5::after {
        width: 48px;
    }

    .stars-2-0::after {
        width: 40px;
    }

    .stars-1-5::after {
        width: 28px;
    }

    .stars-1-0::after {
        width: 20px;
    }

    .stars-0-5::after {
        width: 8px;
    }

    .stars-0::after {
        width: 0;
    }

    .review-stars--lg .stars {
        width: 120px;
        height: 20px;
    }

    .review-stars--lg .stars,
    .review-stars--lg .stars::after {
        background-size: 24px 20px;
        height: 20px;
    }

    .review-stars--lg .stars-5-0::after {
        width: 120px;
    }

    .review-stars--lg .stars-4-5::after {
        width: 106px;
    }

    .review-stars--lg .stars-4-0::after {
        width: 96px;
    }

    .review-stars--lg .stars-3-5::after {
        width: 82px;
    }

    .review-stars--lg .stars-3-0::after {
        width: 72px;
    }

    .review-stars--lg .stars-2-5::after {
        width: 58px;
    }

    .review-stars--lg .stars-2-0::after {
        width: 48px;
    }

    .review-stars--lg .stars-1-5::after {
        width: 34px;
    }

    .review-stars--lg .stars-1-0::after {
        width: 24px;
    }

    .review-stars--lg .stars-0-5::after {
        width: 10px;
    }

    .review-stars--lg .stars-0::after {
        width: 0;
    }

    .review-stars--sm .stars {
        width: 70px;
        height: 10px;
    }

    .review-stars--sm .stars,
    .review-stars--sm .stars::after {
        background-size: 14px 10px;
        height: 10px;
    }

    .review-stars--sm .stars-5-0::after {
        width: 70px;
    }

    .review-stars--sm .stars-4-5::after {
        width: 62px;
    }

    .review-stars--sm .stars-4-0::after {
        width: 56px;
    }

    .review-stars--sm .stars-3-5::after {
        width: 48px;
    }

    .review-stars--sm .stars-3-0::after {
        width: 42px;
    }

    .review-stars--sm .stars-2-5::after {
        width: 34px;
    }

    .review-stars--sm .stars-2-0::after {
        width: 28px;
    }

    .review-stars--sm .stars-1-5::after {
        width: 20px;
    }

    .review-stars--sm .stars-1-0::after {
        width: 14px;
    }

    .review-stars--sm .stars-0-5::after {
        width: 6px;
    }

    .review-stars--sm .stars-0::after {
        width: 0;
    }

    .review-stars .review-star__count {
        vertical-align: text-bottom;
        line-height: initial;
    }
</style>
