import loadScript from '~@helpers/load-script';
import api from '~@api/data-layer';

/**
 *
 * @param {object} cart
 * @param {object} basket
 * @param {object} ometria
 */
function initialiseBasket(cart, basket, ometria) {
    basket.setId(cart.basket_id);

    if (cart.url) {
        basket.setUrl(cart.url);
    }

    if (cart.total) {
        basket.setTotal(cart.total, cart.currency);
    }

    cart.items.forEach((item) => {
        basket.addLineItem(item.product_id, item.quantity, item.price, item.variant_id);
    });

    ometria.setBasket(basket);
}

(async () => {
    try {
        const accountId = window.victoriaplum.config.ometria_account_id;
        const ometriaCDN = `https://cdn.ometria.com/tags/${accountId}.js`;
        const storeId = 'victoriaplum';

        await loadScript(ometriaCDN);
        const { ometriaBaseData: data, ometria } = window;

        if (!(data && ometria)) {
            return;
        }

        const { page: { type = '', page_data: pageData = null } = {} } = data;

        if (type) {
            ometria.init(type, pageData, storeId);
        }

        const { user: { email, firstname } } = data;

        if (email) {
            if (firstname) {
                ometria.identify(email, { firstname });
            } else {
                ometria.identify(email);
            }
        }

        const { order: { id = '' } = {} } = data;

        if (type === 'confirmation' && id) {
            ometria.trackTransaction(id);
        } else {
            const { cart } = data;
            const basket = new ometria.Basket();

            initialiseBasket(cart, basket, ometria);
        }

        window.addEventListener('item-added-to-basket', (ev) => {
            const { csi, quantity } = ev.detail;
            ometria.trackAddToBasket(csi, quantity);
        });

        window.addEventListener('item-removed-from-basket', async () => {
            try {
                const basket = new ometria.Basket();
                const { data: { cart } } = await api.ometriaCart();
                initialiseBasket(cart, basket, ometria);
            } catch (e) {
                throw new Error('unable to init Ometria cart after removal');
            }
        });
    } catch (e) {
        // Silence is golden
    }
})();
