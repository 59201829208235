const keyCodes = [
    {
        name: 'enter',
        value: 13,
    },
    {
        name: 'escape',
        value: 27,
    },
    {
        name: 'space',
        value: 32,
    },
    {
        name: 'up',
        value: 38,
    },
    {
        name: 'down',
        value: 40,
    },
];

const keyCode = (() => {
    const obj = {};

    for (let x = 0; x < keyCodes.length; x += 1) {
        Object.defineProperty(obj, keyCodes[x].name, {
            value: keyCodes[x].value,
            writable: false,
            configurable: false,
            enumerable: true,
        });
    }

    return Object.freeze(obj);
})();

export default keyCode;
