import comparisonTracking from '~@tracking/comparison';

const compareNavLink = document.querySelector('.i-compare-items-nav');
const compareToolbarButton = document.querySelector('.i-compare-items');

if (compareToolbarButton) {
    compareToolbarButton.addEventListener('click', (event) => {
        if (event.target.classList.contains('i-compare-items')) {
            comparisonTracking.access('toolbar');
        }
    });
}

if (compareNavLink) {
    compareNavLink.addEventListener('click', () => {
        comparisonTracking.access('nav-menu');
    });
}
