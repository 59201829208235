/**
 * @param {HTMLElement} lazyElm
 * @param {object} options
 */
export default async function lazyBackgroundImage(lazyElm, options = {}) {
    const observer = new IntersectionObserver(onChange, options);
    observer.observe(lazyElm);

    /**
     * @param {string} src
     * @returns {Promise<any>}
     */
    function preloadImage(src) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = resolve;
            img.onerror = reject;
            img.src = src;
        });
    }

    /**
     * @param {HTMLElement} elem
     */
    function loadBackground(elem) {
        const { src } = elem.dataset;

        if (!src) {
            return;
        }

        preloadImage(src).then(() => {
            elem.style.backgroundImage = `url(${src})`;
            lazyElm.classList.add('loaded');
        }).catch(() => false);
    }

    /**
     * @param {Array} entries
     */
    function onChange(entries) {
        entries.forEach((entry) => {
            const { isIntersecting, intersectionRatio, target } = entry;

            if (isIntersecting || intersectionRatio > 0) {
                loadBackground(target);
                observer.unobserve(target);
                observer.disconnect();
            }
        });
    }
}
