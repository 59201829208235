import Vue from 'vue';
import loginTracking from '~@tracking/login';
import quotesTracking from '~@tracking/quotes';
import offCanvasPanels from './off-canvas-panels';
import user from '../helpers/user';

const quotesNavItem = document.querySelector('.t-track-quotes');
const panel = offCanvasPanels.byId('off-canvas-panel-left');
const loginLink = document.querySelector('.i-burger-nav-login');
const mountPoint = document.querySelector('.i-mount-burger-nav');
const navButton = [...document.querySelectorAll('.i-toggle-burger-nav')];

let hasMounted = false;

export default function mountBurgerNav() {
    if (hasMounted) {
        return;
    }

    new Vue({
        el: mountPoint,
        components: {
            burgerNav: () => import(
                /* webpackChunkName: "burger-nav-dynamic" */
                '../../../vue/component/burger-nav/BurgerNav'
            ),
        },
    });

    hasMounted = true;
}

if (navButton && mountPoint) {
    navButton.forEach((btn) => {
        btn.addEventListener('click', mountBurgerNav);
    });
}

// Track viewing quotes from primary nav by a trade user
if (quotesNavItem && !user.isAgent()) {
    quotesNavItem.addEventListener('click', () => {
        quotesTracking.view('burger-nav');
    });
}

if (panel) {
    if (loginLink) {
        loginLink.addEventListener('click', () => {
            loginTracking.showForm(panel.id);
        });
    }

    [...document.querySelectorAll('.i-toggle-burger-nav')].forEach((elem) => {
        panel.addTrigger(elem);
    });
}
