export default class BasketIcon {
    /**
     * @param {HTMLElement} element
     * @param {HTMLElement} countElement
     */
    constructor(element, countElement) {
        this.setElement(element);
        this.setCountElement(countElement);
    }

    /**
     * @param {HTMLElement} element
     */
    setElement(element) {
        this.element = element;
    }

    /**
     * @param {HTMLElement} countElement
     */
    setCountElement(countElement) {
        this.countElement = countElement;
        this.setCount(this.countElement.innerHTML);
    }

    /**
     * @param {number} count
     */
    setCount(count) {
        this.count = parseInt(count, 10);
        this.redraw();
    }

    redraw() {
        if (this.count >= 1) {
            this.element.classList.remove('empty-header-basket');
        } else {
            this.element.classList.add('empty-header-basket');
        }

        this.countElement.textContent = this.count;
    }
}
