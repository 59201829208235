/**
 * @param {string} type
 * @param {object} data
 * @returns {CustomEvent<any>}
 * @class
 */
export default function CustomEventHelper(type, data = {}) {
    let event;

    try {
        event = new CustomEvent(type, {
            detail: data,
        });
    } catch (err) {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(type, true, true, data);
    }

    return event;
}
