import axios from 'axios';

/**
 * VictoriaPlum.com Favourites
 */
export default {
    csis() {
        return axios.get('/favourites/csis');
    },
    /**
     * @param {string} csi
     * @returns {Promise}
     */
    add(csi) {
        return axios.post('/favourites/add', { csis: csi.split(',') });
    },

    /**
     * @param {string} csi
     * @returns {Promise}
     */
    remove(csi) {
        return axios.post('/favourites/remove', { csis: csi.split(',') });
    },

    /**
     * @param {string} query
     * @returns {Promise}
     */
    filter(query) {
        return axios.get(`/favourites/filter${query}`);
    },
};
