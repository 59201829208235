import passiveIfSupported from '~@helpers/passive-supported';
import Loader from '~@helpers/loader';
import ImageLoader from './three-sixty/image-loader';
import ThreeSixtyViewer from './three-sixty/three-sixty-viewer';
import InputController from './three-sixty/input-controller';
import InputMonitor from './three-sixty/input-monitor';
import Vector2 from './three-sixty/vector2';

export default class ThreeSixty {
    /**
     * @param {HTMLElement} container
     * @param {number} rows
     * @param {number} columns
     * @param {number} initialRow
     * @param {number} initialColumn
     */
    constructor(container, rows, columns, initialRow = 0, initialColumn = 2) {
        const loadingElm = `
            <div class="loading">
                <div class="loader loader--dark loader--xl">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        `;

        const image = container.querySelector('img');

        if (!image) {
            return;
        }

        const { width, height } = container.getBoundingClientRect();
        const imageLoader = new ImageLoader(image.src, rows, columns, width, height);
        const viewer = new ThreeSixtyViewer(image, imageLoader.images, rows, columns);

        viewer.show(initialColumn, initialRow);

        this.button = container.querySelector('.i-start-360');
        if (!this.button) {
            return;
        }

        this.button.classList.add('hidden');
        container.insertAdjacentHTML('beforeend', loadingElm);

        const loader = new Loader(container.querySelector('div.loading'));

        loader.show();

        const promises = imageLoader.load();

        this.loaded = Promise.all(promises.map(p => p.catch(e => e)))
            .then((results) => {
                const hasErrors = results.filter(result => result.type === 'error');

                if (hasErrors.length > 0) {
                    return new Error(results);
                }

                this.button.classList.remove('hidden');

                const monitor = new InputMonitor(container, image);
                this.controller = new InputController(viewer, monitor, new Vector2(initialColumn, initialRow));

                loader.hide();

                ['click', 'touchstart'].forEach((type) => {
                    this.button.addEventListener(type, () => {
                        this.button.classList.add('hidden');
                        this.controller.start();
                    }, passiveIfSupported);
                });

                return true;
            });
    }

    stop() {
        this.controller.stop();
        this.button.classList.remove('hidden');
    }
}
