/**
 *
 * @param {Event} event
 */
function removeLoader(event) {
    const { componentName } = event.detail;
    const element = document.querySelector(`[data-component="${componentName}"]`);

    // eslint-disable-next-line no-unused-expressions
    element?.classList.add('hidden');
}

/**
 *
 * @param {Event} event
 */
function handleError(event) {
    removeLoader(event);

    const { componentName } = event.detail;
    const errorMessage = document.querySelector(`[data-component-error="${componentName}"]`);

    // eslint-disable-next-line no-unused-expressions
    errorMessage?.classList.remove('hidden');
}

window.addEventListener('async-component-loaded', removeLoader);
window.addEventListener('async-component-failed', handleError);
