import installPwa from '~@componentJs/pwa-installer';
import {
    getCookie,
    hasCookie,
    setCookie,
    expireInDays,
} from '~@helpers/cookie';
import Browser from '~@helpers/browser';
import pwaTracker from '~@tracking/pwa';

// Version allows us to 'cache-bust' the cookie if we have new features available
// and want to override a dismissal.
const NAME = 'pwa-handled';
const VERSION = '0.01';

/**
 * This is made available as a callback, it is used to set the cookie when the pwa is
 * either installed or dismissed
 */
function handler() {
    setCookie(NAME, { value: VERSION });
}

// Update the cookie if we have a new version of the PWA
const cookie = getCookie(NAME);

if (cookie && cookie !== VERSION) {
    setCookie(NAME, { expires: expireInDays(-1) });
}

// Track installs - including the ones done manually, not via the modal.
window.addEventListener('appinstalled', () => {
    handler();
    pwaTracker.installed(window.location.pathname);
});

window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();

    if (!hasCookie(NAME) && Browser.isAndroid()) {
        installPwa(e, handler);
    }
});
