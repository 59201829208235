<template>
    <svg
        :width="width"
        :height="height"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        class="icon-outline"
    >
        <use :xlink:href="spritePath(name, 'outline')" />
    </svg>
</template>

<script>
import icon from '~@mixins/icon';

export default {
    name: 'IconOutline',
    mixins: [icon],
    props: {
        name: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '32',
        },
        height: {
            type: String,
            default: '32',
        },
    },
};
</script>
