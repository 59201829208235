import { getTimeRemaining } from '~@helpers/time';

export default class Countdown {
    constructor() {
        this.interval = null;
        this.hasEnded = false;
    }

    /**
     * @param {Date} datetime
     * @param {Function} callback
     */
    start(datetime, callback) {
        const endTime = new Date(datetime);

        if (!(endTime instanceof Date)) {
            return;
        }

        const intervalCallback = () => {
            const milliseconds = endTime - Date.now();

            if (milliseconds <= 0) {
                this.stop();
            }

            callback(getTimeRemaining(milliseconds));
        };

        this.interval = setInterval(intervalCallback, 1000);
    }

    stop() {
        clearInterval(this.interval);
        this.hasEnded = true;
    }
}
