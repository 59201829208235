/** Handle fixed positioning elements in the viewport * */
import throttle from 'lodash/throttle';
import passiveIfSupported from '~@helpers/passive-supported';

/**
 * Milliseconds for throttle
 *
 * @type {number}
 */
const THROTTLE_VAL = 50;

/**
 * Bind the throttled scroll event handler to toggle the sticky class
 *
 * @param {number} threshold
 * @param {Element} elem
 * @param {string} className
 * @private
 */
function stickyScroll(threshold, elem, className) {
    const throttled = throttle(() => {
        if (window.pageYOffset >= threshold) {
            elem.classList.add(className);
        } else {
            elem.classList.remove(className);
        }
    }, THROTTLE_VAL);

    window.addEventListener('scroll', throttled, passiveIfSupported);
}

/**
 * The default position in the normal flow, accounting for scroll offsets
 *
 * @param {Element} elem
 * @returns {number}
 */
function defaultPosition(elem) {
    const { top } = elem.getBoundingClientRect();
    const scroll = window.pageYOffset;
    return top + scroll;
}

/**
 * Return an element form the querySelector if one exists
 *
 * @param {string} selector
 * @returns {Element|null}
 */
function delegateElement(selector) {
    try {
        return document.querySelector(selector);
    } catch (e) {
        return null;
    }
}

/**
 * Allow delegation of the class toggle to another element.
 * Useful when we need to control other child elements in relation to the sticky element.
 *
 * @param {Element} elem
 * @param {string} selector
 * @param {string} className
 */
export default function sticky(elem, selector = '', className = 'sticky') {
    const threshold = defaultPosition(elem);

    if (!threshold) {
        return;
    }

    const delegate = delegateElement(selector);

    if (delegate) {
        const delElem = delegate;
        stickyScroll(threshold, delElem, className);
        return;
    }

    stickyScroll(threshold, elem, className);
}
