import axios from 'axios';

/**
 * VictoriaPlum.com Cart
 */
export default {
    /**
     * @param {Array} csis
     * @param {number} quantity
     * @returns {Promise}
     */
    add(csis, quantity) {
        return axios.post('/cart/items', { csis, quantity });
    },

    /**
     * @param {string} csi
     * @returns {Promise}
     */
    remove(csi) {
        return axios.delete(`/cart/items/${csi}`);
    },

    /**
     * @param {string} csi
     * @param {number} quantity
     * @returns {Promise}
     */
    update(csi, quantity) {
        return axios.put(`/cart/items/${csi}`, { quantity });
    },

    /**
     * @returns {Promise}
     */
    fetchAll() {
        return axios.get('/cart/data.json');
    },

    /**
     * @returns {Promise}
     */
    fetchItems() {
        return axios.get('/cart/items.json');
    },

    /**
     * @returns {Promise}
     */
    fetchSamples() {
        return axios.get('/cart/samples.json');
    },

    /**
     * @param {string} couponCode
     * @returns {Promise}
     */
    discount(couponCode) {
        return axios.patch('/cart/discount', { couponCode });
    },
};
