/**
 * The number of days before the cookie expires
 *
 * @param {number} days
 * @returns {string}
 */
export function expireInDays(days = 30) {
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + days);

    return expiry.toUTCString();
}

/**
 * Generate a random cookie value
 *
 * @returns {*}
 */
export function generateRandomValue() {
    return Math.random();
}

/**
 * Checks for Cookie
 *
 * @param {string} name
 * @returns {boolean}
 */
export function hasCookie(name) {
    const cookies = new Set(document.cookie.split(';').map(cookie => cookie.split('=').shift().trim()));
    return cookies.has(name);
}

/**
 * Gets Cookie Value
 *
 * @param {string} name
 * @returns {*}
 */
export function getCookie(name) {
    let val = '';

    const cookies = new Set(document.cookie.split(';').map(cookie => cookie.trim()));

    cookies.forEach((cookie) => {
        if (cookie.split('=').includes(name)) {
            // eslint-disable-next-line prefer-destructuring
            val = cookie.split('=')[1];
        }
    });

    return val;
}

/**
 * @param {string} name
 * @param {number|string} value
 * @param {string} path
 * @param {string} domain
 * @param {string }expires
 * @returns {boolean}
 */
export function setCookie(name, {
    value = generateRandomValue(),
    path = '/',
    domain = window.location.hostname,
    expires = expireInDays(90),
} = {}) {
    if (!name) {
        return false;
    }

    document.cookie = `${name}=${value}; domain=${domain}; expires=${expires}; path=${path}`;

    return true;
}
