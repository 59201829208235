import dl from '~@tracking/data-layer';

const category = 'social';

export default {
    socialLinkClicked(label) {
        dl.addTrackingEvent({
            category,
            action: 'social-media',
            label,
        });
    },
};
