import socialTracking from '~@tracking/social';

const socialLinks = document.querySelectorAll('.t-social-tracking');

[...socialLinks].forEach((link) => {
    link.addEventListener('click', () => {
        const label = link.getAttribute('data-label');
        socialTracking.socialLinkClicked(label);
    });
});
