/**
 * @param {string} message
 * @param {HTMLElement} node
 */
function confirmCopy(message, node) {
    node.textContent = message;
    node.classList.add('copy-success');
}

/**
 * @param {string} text
 * @param {HTMLElement} node
 */
function reset(text, node) {
    node.textContent = text;
    node.classList.remove('copy-success');
}

/**
 * @param {string} txt
 */
function copyToClipboard(txt) {
    if (!txt) {
        return;
    }

    const tmp = document.createElement('input');
    tmp.setAttribute('readonly', 'true');
    tmp.value = txt;
    tmp.classList.add('tmp-copy-text');
    document.body.appendChild(tmp);
    tmp.select();

    // Handle IOS selection
    if (window.getSelection().toString() !== txt) {
        tmp.selectionStart = 0;
        tmp.selectionEnd = 99;
    }

    document.execCommand('copy');
    tmp.parentNode.removeChild(tmp);
}

/**
 * @param {HTMLElement} elem
 * @returns {string}
 */
function createMessage(elem) {
    return elem.getAttribute('data-copyconfirm') || 'Copied';
}

/**
 * @param {HTMLElement} element
 * @returns {boolean}
 */
function inProgress(element) {
    return element.classList.contains('copy-success');
}

/**
 * @returns {boolean}
 */
function copySupported() {
    return !!document.queryCommandSupported && !!document.queryCommandSupported('copy');
}

/**
 * @param {HTMLElement} element
 * @returns {*|boolean}
 */
function canCopy(element) {
    return element && copySupported() && !inProgress(element);
}

/**
 * Handle fallback on non supporting browsers
 *
 * @param {HTMLElement} target
 */
function fallback(target) {
    target.style.cursor = 'text';
    let range;
    let selection;

    if (document.body.createTextRange) {
        range = document.body.createTextRange();
        range.moveToElementText(target);
        range.select();
    } else if (window.getSelection) {
        selection = window.getSelection();
        range = document.createRange();
        range.selectNodeContents(target);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}

/**
 * Expose this as a module.
 *
 * @param {HTMLElement} target
 */
export default function copyTextToClipboard(target) {
    const originalText = target.innerText;

    try {
        if (canCopy(target)) {
            copyToClipboard(originalText, target);
            confirmCopy(createMessage(target), target);
            window.setTimeout(() => {
                reset(originalText, target);
            }, 2000);
        } else {
            fallback(target);
        }
    } catch (err) {
        fallback(target);
    }
}

// Make this available to anything with the .js-copy-text class
[...document.querySelectorAll('.i-copy-text')].forEach((el) => {
    el.addEventListener('click', () => {
        copyTextToClipboard(el);
    }, false);
});
