import dl from '~@tracking/data-layer';

const accordions = [...document.querySelectorAll('.i-footer-accordion')];

/**
 * @param {Array} collection
 */
function closeAll(collection) {
    collection.forEach((item) => {
        item.classList.remove('is-active');
    });
}

/**
 * @param {Event} e
 */
function toggleLists(e) {
    const content = e.currentTarget;
    const isActive = content.classList.contains('is-active');

    closeAll(accordions);

    if (!isActive) {
        content.classList.add('is-active');
    }
}

if (accordions.length > 0) {
    accordions.forEach((accordion) => {
        accordion.addEventListener('click', toggleLists, false);
    });
}

// Link tracking
const footer = document.querySelector('.site-footer');

if (footer) {
    const links = [...footer.querySelectorAll('.i-footer-link')];

    links.forEach(link => {
        link.addEventListener('click', () => {
            if (link.dataset.label) {
                dl.addTrackingEvent({
                    category: 'footer',
                    action: 'footer link',
                    label: link.dataset.label,
                });
            }
        });
    });
}
