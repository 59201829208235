<template>
    <component
        :is="tagName"
        class="carousel__item"
    >
        <slot />
    </component>
</template>

<script>
export default {
    name: 'CarouselItem',
    props: {
        tagName: {
            type: String,
            default: () => 'li',
        },
    },
};
</script>
