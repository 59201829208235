import dl from '~@tracking/data-layer';

import { trackEvent } from '~@helpers/tracker';

/**
 *
 * @returns { string }
 */
function getQuery() {
    const input = document.querySelector('.i-quicksearch-input');
    return input ? input.value : '';
}

const config = {
    category: 'search',
};

const trackingElements = [
    {
        name: 'search-default-suggestion',
        action: 'quick_search_default_suggestion',
    },
    {
        name: 'search-suggestion',
        action: 'quick_search_suggestions_selected',
        label: getQuery,
    },
    {
        name: 'search-product',
        action: 'quick_search_products_selected',
        label: getQuery,
    },
    {
        name: 'search-article',
        action: 'quick_search_articles_selected',
        label: getQuery,
    },
    {
        name: 'show-all-link',
        action: 'quick_search_show_all_selected',
        label: getQuery,
    },
];

trackingElements.forEach((item) => {
    trackEvent({ ...config, ...item });
});

export default {
    trackQuery(label) {
        dl.addTrackingEvent({
            category: config.category,
            action: 'quick_search_query_submitted',
            label,
        });

        dl.addGa4Event({
            event: 'search',
            search_term: label,
        });
    },
    searchOpened() {
        dl.addTrackingEvent({
            category: config.category,
            action: 'quick_search_opened',
            label: '',
        });
    },
    searchResultsShown() {
        dl.addTrackingEvent({
            category: config.category,
            action: 'quick_search_results_shown',
            label: '',
        });
    },
};
