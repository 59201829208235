import SwipeJS from 'swipejs';
import lazyLoadImages from '~@helpers/lazy-load-images';
import pageTracking from '~@tracking/page';
import page from '~@helpers/page-service';

/**
 * Update the active indicator to have an is-active class
 *
 * @param {number} index
 * @param {Array} indicators
 */
function updateActive(index, indicators) {
    indicators.forEach((elem) => {
        elem.classList.remove('is-active');
    });
    indicators[index].classList.add('is-active');
}

/**
 * Initialise a new SwipeJS Carousel
 *
 * @param {object} carousel
 * @param {object} options
 * @param {Function} callbackFunc
 *
 * @returns {object}
 */
export default function initialiseCarousel(carousel, options = {}, callbackFunc = () => {}) {
    const indicators = [...carousel.parentNode.parentNode.querySelectorAll('.i-image-carousel-indicator')];
    const links = [...carousel.querySelectorAll('.i-image-carousel-link')];

    const defaultOpts = {
        continuous: true,
        speed: 500,
        draggable: true,
        startSlide: 0,
        autoRestart: true,
        stopPropagation: true,
        callback: (index, elem, dir) => {
            updateActive(index, indicators);

            if (callbackFunc) {
                callbackFunc(index, elem, dir);
            }
        },
    };
    const galleryOpts = Object.assign(defaultOpts, options);
    const gallery = new SwipeJS(carousel, galleryOpts);

    lazyLoadImages(carousel);

    indicators.forEach((item, index) => {
        let slideIndex = index;
        let label = index + 1;

        item.addEventListener('click', () => {
            if (item.hasAttribute('data-slide')) {
                slideIndex = item.getAttribute('data-slide');
            }

            gallery.slide(slideIndex, 500);

            if (page.isHomepage()) {
                if (item.hasAttribute('data-tracking')) {
                    label = item.getAttribute('data-tracking');
                }

                pageTracking.trackEvent({
                    category: 'home page',
                    action: 'banner_tabs',
                    label,
                });
            }
        });
    });

    if (page.isHomepage()) {
        links.forEach((item, index) => {
            const label = item.getAttribute('title') || index + 1;
            item.addEventListener('click', () => {
                pageTracking.trackEvent({
                    category: 'home page',
                    action: 'carousel_banners',
                    label,
                });
            });
        });
    }

    const controls = [...carousel.parentNode.querySelectorAll('.i-image-carousel-control')];
    controls.forEach((control) => {
        control.addEventListener('click', () => {
            const action = control.getAttribute('data-action');
            const slide = control.getAttribute('data-slide');
            if (action === 'previous') {
                gallery.prev();
            } else if (action === 'next') {
                gallery.next();
            }

            if (slide !== null) {
                gallery.slide(Number(slide), 500);
            }
        });
    });

    carousel.addEventListener('mouseover', () => {
        gallery.stop();
    });
    carousel.addEventListener('mouseleave', () => {
        gallery.restart();
    });

    return gallery;
}

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.i-image-carousel')].forEach((element) => {
        initialiseCarousel(element);
    });

    /*
        This hack fixes an intermittent issue on iOS devices
        when the carousel tries to initialise the first slide
        but for some reason stays hidden.
    */
    window.setTimeout(() => {
        [...document.querySelectorAll('.image-carousel__slide')].forEach((element) => {
            element.style.display = 'inline-block';
        });
    }, 1000);
});
