import Vue from 'vue';
import Vuex from 'vuex';
import PortalVue from 'portal-vue';
import searchStore from '~@store/search';
import pluralise from '~@helpers/pluralise';

Vue.use(PortalVue);
Vue.filter('pluralise', pluralise);

const quickSearchMount = document.querySelector('.i-mount-quick-search');

window.addEventListener('mount-search', mountSearch);

function mountSearch() {
    if (quickSearchMount) {
        new Vue({
            el: quickSearchMount,
            components: {
                QuickSearch: () => import(
                    /* webpackChunkName: "quick-search" */
                    '../../../vue/component/quick-search/QuickSearch'
                ),
            },
            store: new Vuex.Store(searchStore),
        });

        window.removeEventListener('mount-search', mountSearch);
    }
}
