export default class Loader {
    /**
     * @param {HTMLElement} loader
     */
    constructor(loader) {
        this.loader = loader;
    }

    show() {
        this.loader.classList.add('is-visible');
    }

    hide() {
        this.loader.classList.remove('is-visible');
    }
}
