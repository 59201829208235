import dl from '~@tracking/data-layer';

export default {
    /**
     * @param {string} list
     * @param {string} csi
     */
    add(list, csi) {
        dl.addTrackingEvent({
            category: 'comparison',
            action: 'add',
            label: list,
        });

        dl.addTrackingEvent({
            category: 'comparison',
            action: 'add_csi',
            label: csi,
        });
    },

    /**
     * @param {string} list
     * @param {string} csi
     */
    remove(list, csi) {
        dl.addTrackingEvent({
            category: 'comparison',
            action: 'remove',
            label: list,
        });

        dl.addTrackingEvent({
            category: 'comparison',
            action: 'remove_csi',
            label: csi,
        });
    },
    /**
     * @param {string} list
     */
    access(list) {
        dl.addTrackingEvent({
            category: 'comparison',
            action: 'access',
            label: list,
        });
    },
};
