import passiveIfSupported from '~@helpers/passive-supported';
import Vector2 from './vector2';

export default class InputMonitor {
    /**
     * @param {HTMLElement} container
     * @param {HTMLElement} targetImage
     */
    constructor(container, targetImage) {
        this.startPosition = null;
        this.difference = new Vector2(0, 0);
        this.container = container;
        this.targetImage = targetImage;

        this.registerMouseListeners();
        this.registerTouchListeners();
        this.registerDragListeners();
    }

    /**
     * @param {Event} event
     */
    setStartPosition(event) {
        this.startPosition = new Vector2(event.clientX, event.clientY);
    }

    completeMovement() {
        if (this.startPosition && this.endHandler) {
            this.endHandler();
        }

        this.startPosition = null;
    }

    /**
     * @param {Event} event
     */
    trackMovement(event) {
        if (!this.startPosition) {
            return;
        }

        const currentPosition = new Vector2(event.clientX, event.clientY);
        this.difference = currentPosition.subtract(this.startPosition);

        if (this.moveHandler) {
            this.moveHandler(this.difference);
        }
    }

    registerDragListeners() {
        this.targetImage.addEventListener('dragstart', (event) => {
            event.preventDefault();
        });
    }

    registerTouchListeners() {
        this.targetImage.addEventListener('touchstart', ({ changedTouches: [touch] }) => {
            this.setStartPosition(touch);
        }, passiveIfSupported);

        ['touchend', 'touchcancel'].forEach((type) => {
            this.container.addEventListener(type, () => {
                this.completeMovement();
            });
        }, passiveIfSupported);

        this.container.addEventListener('touchmove', ({ changedTouches: [touch] }) => {
            this.trackMovement(touch);
        }, passiveIfSupported);
    }

    registerMouseListeners() {
        ['mousedown', 'pointerdown', 'MSPointerDown'].forEach((type) => {
            this.targetImage.addEventListener(type, (event) => {
                this.setStartPosition(event);
            });
        });

        ['mouseup', 'mouseleave', 'pointerup', 'pointerleave', 'MSPointerUp', 'MSPointerLeave'].forEach((type) => {
            this.container.addEventListener(type, () => {
                this.completeMovement();
            });
        });

        ['mousemove', 'pointermove', 'MSPointerMove'].forEach((type) => {
            this.container.addEventListener(type, (event) => {
                this.trackMovement(event);
            });
        });
    }

    /**
     * @param {Function} callback
     */
    onMove(callback) {
        this.moveHandler = callback;
    }

    /**
     * @param {Function} callback
     */
    onEnd(callback) {
        this.endHandler = callback;
    }
}
