import dl from '~@tracking/data-layer';
import api from '~@api/data-layer';

export default async function viewCart() {
    const { data: { ecommerce = {} } = {} } = await api.fetchCartData();

    // The cart data may contain parameters we don't need for this event
    // The 55 audit recommended we remove them
    delete ecommerce.shipping_tier;
    delete ecommerce.payment_type;

    dl.addEcommerceEvent({
        event: 'view_cart',
        ecommerce,
    });
}
