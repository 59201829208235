/**
 * @param {HTMLElement} toggle
 */
function passwordToggle(toggle) {
    const passwd = toggle.parentNode.querySelector('input[type="password"]');

    if (passwd) {
        toggle.addEventListener('click', () => {
            const isText = passwd.type === 'text';

            if (isText) {
                passwd.type = 'password';
                toggle.classList.remove('is-plain-text');
            } else {
                toggle.classList.add('is-plain-text');
                passwd.type = 'text';
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.i-password-toggle')].forEach(passwordToggle);
});
