const svgSpritePath = window.victoriaplum.config.svg_sprite_path || '';

const icon = {
    methods: {
        /**
         * @param {string} name
         * @param {string} type
         * @returns {string}
         */
        spritePath(name, type) {
            return `${svgSpritePath}#${type}-${name}`;
        },
    },
};

export default icon;
