export default class Vector2 {
    /**
     * @param {number} x
     * @param {number} y
     */
    constructor(x, y) {
        if (typeof x !== 'number' || typeof y !== 'number') {
            throw new TypeError('Both parameters (x, y) must be numbers');
        }

        this.x = x;
        this.y = y;
    }

    /**
     * @param {Vector2} vector
     *
     * @returns {Vector2}
     */
    subtract(vector) {
        return new Vector2(this.x - vector.x, this.y - vector.y);
    }
}
