/**
 *
 * @param {string} url
 * @returns {Promise}
 */
export default function loadScript(url) {
    return new Promise((resolve, reject) => {
        const scriptTag = document.createElement('script');

        scriptTag.addEventListener('load', resolve);
        scriptTag.addEventListener('error', reject);

        document.head.appendChild(scriptTag);
        scriptTag.setAttribute('src', url);
    });
}
