import dl from '~@tracking/data-layer';
import viewCart from '~@tracking/enhanced-ecommerce/view-cart';
import { trackEvent, registerEvents } from '~@helpers/tracker';

const config = {
    category: 'ecommerce',
};

const trackingElements = [
    {
        name: 'continue-to-payment-sticky-cta',
        action: 'cart_page',
        label: 'continue to payment sticky CTA',
    },
    {
        name: 'continue-to-payment-cta',
        action: 'cart_page',
        label: 'continue to payment CTA',
    },
    {
        name: 'continue-to-payment-paypal-cta',
        action: 'cart_page',
        label: 'PayPal CTA',
    },
    {
        name: 'login-link',
        action: 'cart_page',
        label: 'login link',
    },
];

// Vue components use the v-track directive, so we only need to attach the listener
trackingElements.forEach((item) => {
    trackEvent({ ...config, ...item });
});

registerEvents(trackingElements);

export default {
    viewCart,
    /**
     * @param {object} data
     */
    updateBasket(data) {
        const products = data.uvBasket.line_items || [];

        let lineItems;

        // We need to clear the array before updating with the latest basket changes.
        // This is because of how the dataLayer merges Arrays and Objects recursively, and
        // tries to maintain key/value order.

        // See below article for a full explanation.
        // https://www.simoahava.com/analytics/google-tag-manager-data-model/
        dl.push({
            basket: { line_items: undefined },
        });

        if (products.length > 0) {
            lineItems = {
                line_items: products.map((product) => ({
                    product: {
                        quantity: product.quantity,
                        id: product.product.id,
                        name: product.product.name,
                        brand: product.product.brand,
                        price: product.product.unit_price,
                        variant: product.product.sku_code,
                        category: product.product.category,
                    },
                })),
            };
        }

        dl.push({
            basket: Object.assign(data.uvBasket, lineItems),
        });

        if (data.newlyCreated) {
            dl.addTrackingEvent({
                category: 'basket',
                action: 'created',
                label: '',
            });
        }
    },

    deliveryLeadTime({ action = 'In Stock', label = '' }) {
        dl.addTrackingEvent({
            category: 'Delivery lead time',
            action,
            label,
        });
    },
};
