import dl from '~@tracking/data-layer';

export default {
    /**
     * @param {string} category
     * @param {string} action
     * @param {string} label
     * @param {object} fieldsObject
     */
    trackEvent({
        category = 'blog',
        action = '',
        label = '',
        fieldsObject = {},
    }) {
        dl.addTrackingEvent({
            category,
            action,
            label,
            fieldsObject,
        });
    },
};
