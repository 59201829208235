import dl from '~@tracking/data-layer';

export default {
    /**
     * @param {string} list
     */
    view(list) {
        dl.addTrackingEvent({
            category: 'quotes',
            action: 'view',
            label: list,
        });
    },
    viewQuoteDetail() {
        dl.addTrackingEvent({
            category: 'quotes',
            action: 'view_details',
            label: '',
        });
    },
    viewExpiredQuote() {
        dl.addTrackingEvent({
            category: 'quotes',
            action: 'view_expired',
            label: '',
        });
    },
    downloadQuotePdf() {
        dl.addTrackingEvent({
            category: 'quotes',
            action: 'download_pdf',
            label: '',
        });
    },
    convertToQuote() {
        dl.addTrackingEvent({
            category: 'quotes',
            action: 'convert_quote',
            label: '',
        });
    },
    checkoutQuote() {
        dl.addTrackingEvent({
            category: 'quotes',
            action: 'checkout_quote',
            label: '',
        });
    },
};
