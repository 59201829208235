import { setCookie } from '../helpers/cookie';

const alertElem = document.getElementById('covid-alert');

if (alertElem) {
    const alertClose = alertElem.querySelector('.i-dismiss');

    alertClose.addEventListener('click', () => {
        setCookie('covid_alert_v2');
    });
}
