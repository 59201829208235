import axios from 'axios';

/**
 * Victoriaplum Newsletter
 */
export default {
    /**
     * @returns {Promise}
     */
    subscribe({ email = '', modal = false }) {
        const url = modal ? '/newsletter-modal' : 'newsletter';

        return axios.post(url, { subscribe_email: email });
    },
};
