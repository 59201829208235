import ActivatableItem from '~@componentJs/activatable-item';
import overlayMediator from '~@helpers/overlay-mediator';
import Backdrop from '~@componentJs/backdrop';

const moduleId = 'popper';

class Popper extends ActivatableItem {
    setActive() {
        super.setActive();
        overlayMediator.register(moduleId, this.setInactive.bind(this));
    }

    setInactive() {
        overlayMediator.unregister(moduleId);

        // This covers an edge case caused by the delay on mouseleave of the popper.
        // This could potentially hide the backdrop that is being displayed for
        // another overlaying component, i.e. quick search
        if (Backdrop.visible() && overlayMediator.queueIsEmpty()) {
            Backdrop.hide();
        }

        super.setInactive();
    }
}

/**
 * @param {HTMLElement} element
 * @returns {Popper}
 */
export default function popper(element) {
    return new Popper(element, false);
}
