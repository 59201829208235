<template>
    <Modal
        v-ate="'email-signup-modal'"
        :active="active"
        class="email-signup-modal"
        @closeModal="closeModal"
    >
        <button
            class="close-email-icon"
            @click="closeModal"
        >
            <IconGlyph name="close" />
        </button>
        <h4 class="heading heading--md">
            Sorry for the interruption...
        </h4>
        <h2 class="heading heading--lg">
            How does £20 OFF* your first order sound?
        </h2>
        <p class="text--v1">
            You'll also be the first to find out about our exclusive deals and promotions.
        </p>
        <section
            v-if="!subscribed"
            :class="[{'inputs--error': errorMessage}]"
        >
            <input
                v-model="email"
                class="input rounded"
                type="email"
                autocomplete="on"
                placeholder="Enter your email address"
                aria-label="Email"
            >
            <button
                class="btn rounded btn--primary"
                @click="subscribe"
            >
                Get my £20 OFF code
            </button>
            <p
                v-if="errorMessage"
                class="form__error"
            >
                {{ errorMessage }}
            </p>
        </section>
        <section
            v-else
            class="success-message"
        >
            <p>
                Thanks for subscribing. Check your inbox soon for the very latest bathroom ideas and inspiration,
                as well as offers and discounts.
            </p>
            <p>
                To claim an extra <strong>£20 OFF</strong> your first order when you <strong>spend over £299</strong>
            </p>
            <p>
                Use code
                <span
                    class="voucher-tag"
                    data-copyconfirm="Code copied"
                    @click="copyCode($event)"
                >
                    V24A90Z22P
                </span>
                at the basket
            </p>
        </section>
        <p class="email-signup-modal__disclaimer text--v2">
            We'll never share your email address and you can unsubscribe at any time.
        </p>
        <p
            class="text--v2 link"
            @click="closeModal"
        >
            {{ subscribed ? 'Click here to close' : 'No thanks' }}
        </p>
        <p class="text--v2">
            *Minimum spend of £299
        </p>
    </Modal>
</template>

<script>
import Modal from '~@common/Modal';
import IconGlyph from '~@common/icon/IconGlyph';
import NewsletterApi from '~@api/newsletter';
import copyTextToClipboard from '~@helpers/click-to-copy';

export default {
    name: 'EmailSignupModal',
    components: {
        Modal,
        IconGlyph,
    },
    props: {
        active: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    data() {
        return {
            email: '',
            errorMessage: '',
            subscribed: false,
        };
    },
    inject: ['dismiss'],
    methods: {
        async subscribe() {
            const payload = { email: this.email, modal: true };
            NewsletterApi.subscribe(payload)
                .then(() => {
                    this.subscribed = true;
                })
                .catch((err) => {
                    try {
                        // The error messages come back in a zero indexed array,
                        // Just get the first one, it's all there usually is.
                        [this.errorMessage] = err.response.data.subscribe_email;
                    } catch (e) {
                        this.errorMessage = 'Something went wrong, please try again.';
                    }
                });
        },
        closeModal() {
            this.dismiss();
            this.subscribed = false;
            this.email = '';
            this.errorMessage = '';
        },
        copyCode(e) {
            copyTextToClipboard(e.target);
        },
    },
};
</script>
<style scoped>
    .email-signup-modal >>> .modal__content {
        position: relative;
        top: 5px;
        max-width: 770px;
        margin: var(--gutter-default);
        padding: var(--gutter-default);
        background-color: var(--charcoal-10);
        border-radius: 3px;

        @add-mixin mobile-landscape {
            top: 100px;
            padding: var(--gutter-large);
            background-image: url('https://victoriaplum.imgix.net/images/modal/model-background.png?format=auto&q=55');
            background-size: cover;
        }
    }

    @media (min-width: 770px) {
        .email-signup-modal >>> .modal__content {
            margin: var(--gutter-default) auto;
        }
    }

    .email-signup-modal .close-email-icon {
        position: absolute;
        top: 12px;
        right: 8px;
        border: none;
        background-color: initial;
        cursor: pointer;

        svg {
            fill: var(--charcoal-100);
        }
    }

    .email-signup-modal h4 {
        color: var(--charcoal-80);
        font-size: 16px;
        font-weight: 200;

        @add-mixin mobile-landscape {
            font-size: 22px;
        }
    }

    .email-signup-modal h2 {
        color: var(--purple-100);

        @add-mixin mobile-landscape {
            max-width: 400px;
        }
    }

    .email-signup-modal p {
        margin-bottom: 16px;
        font-size: 14px;

        @add-mixin mobile-landscape {
            font-size: 16px;
        }
    }

    .email-signup-modal form {
        display: block;
        max-width: 560px;
        margin: 0;

        @add-mixin tablet-portrait {
            display: inline;
        }
    }

    .email-signup-modal input {
        margin-bottom: 8px;

        @media (min-width: 643px) {
            max-width: 340px;
            margin: 0 8px 0 0;
        }
    }

    .email-signup-modal >>> .form__text {
        margin: 0;
    }

    .email-signup-modal .btn {
        width: 100%;

        @add-mixin mobile-landscape {
            max-width: 210px;
        }
    }

    .email-signup-modal p.email-signup-modal__disclaimer {
        font-size: 12px;
    }

    .email-signup-modal .form__error {
        font-size: 14px;
        color: var(--red-100);
        font-weight: var(--font-weight-heavy);
    }

    .email-signup-modal .success-message {
        background: rgba(255,255,255, 0.6);
        border: 2px solid var(--green-100);
        border-radius: var(--default-border-radius);
        text-align: center;
        line-height: var(--default-line-height);
        padding: 16px;
        margin: 8px;
    }
</style>
