import Vue from 'vue';

/**
 * @param {HTMLElement} el
 * @param {string} value
 * @param {string} name
 */
function handleSelector(el, { value, name }) {
    if (window.victoriaplum.env.test && value) {
        el.setAttribute(`data-${name}`, value);
    }
}

const testDirective = {
    bind: handleSelector,
    update: handleSelector,
};

Vue.directive('at', testDirective);
Vue.directive('atc', testDirective);
Vue.directive('ate', testDirective);

export default testDirective;
