import dl from '~@tracking/data-layer';

export default {
    /**
     * @param {string} form
     */
    showForm(form) {
        dl.addTrackingEvent({
            category: 'login',
            action: 'showLoginFrom',
            label: form,
        });
    },
    /**
     * @param {string} form
     */
    attemptedLogin(form) {
        dl.addTrackingEvent({
            category: 'login',
            action: 'attemptLogin',
            label: form,
        });
    },
};
