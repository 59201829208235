import dl from '~@tracking/data-layer';

// https://developers.google.com/tag-manager/enhanced-ecommerce#cart

export default {
    addToCart(response, location = '') {
        const { productsAdded } = response;

        const mappedProducts = productsAdded.map((product) => ({
            name: product.name,
            id: product.sku_code,
            price: product.unit_price,
            brand: product.brand,
            category: product.category,
            variant: product.id,
            quantity: product.quantity,
        }));

        const mappedProductsMinimal = productsAdded.map((product) => ({
            id: product.sku_code,
            name: product.name,
            brand: product.brand,
            category: product.category,
            variant: product.id,
        }));

        dl.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'GBP',
                add: {
                    products: mappedProducts,
                },
            },
        });

        dl.push({
            event: 'productAdd',
            ecommerce: {
                add: {
                    actionField: {
                        list: location,
                    },
                    products: mappedProductsMinimal,
                },
            },
        });

        const { ecommerce } = response;
        ecommerce.items.forEach((item) => { item.item_list_name = location; });

        dl.addEcommerceEvent({
            event: 'add_to_cart',
            ecommerce,
        });
    },
};
