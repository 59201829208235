<script>
export default {
    name: 'InputBase',
    props: {
        id: {
            type: String,
            required: false,
            default: () => '',
        },
        formId: {
            type: String,
            required: false,
            default: () => '',
        },
        label: {
            type: String,
            required: false,
            default: () => '',
        },
        required: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        value: {
            type: [String, Number],
            required: false,
            default: () => '',
        },
        name: {
            type: String,
            required: false,
            default: () => '',
        },
        type: {
            type: String,
            required: false,
            default: () => '',
        },
        errors: {
            type: Array,
            required: false,
            default: () => [],
        },
        testSelector: {
            type: String,
            required: false,
            default: () => '',
        },
        classList: {
            type: String,
            required: false,
            default: '',
        },
        hint: {
            type: String,
            required: false,
            default: () => '',
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        autocomplete: {
            type: String,
            required: false,
            default: 'Off',
        },
        ariaLabel: {
            type: String,
            required: false,
            default: 'password',
        },
    },
    computed: {
        hasError() {
            return this.errors.length > 0;
        },
        errorMessage() {
            return this.hasError ? this.errors[0].message : '';
        },
    },
    watch: {
        value(value) {
            this.$emit('validate', { field: this.name, value });
        },
    },
    methods: {
        update(event) {
            const { value } = event.target;
            this.$emit('input', value);
            this.$emit('validate', { field: this.name, value });
        },
    },
};
</script>
