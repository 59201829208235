import axios from 'axios';

const baseUrl = '/data-layer/api/v1';

/**
 * VictoriaPlum UV
 */
export default {
    /**
     *
     * @returns {Promise}
     */
    fetchCartData() {
        return axios.get(`${baseUrl}/cart`);
    },

    /**
     *
     * @param {string} csi
     * @returns {Promise}
     */
    fetchVariantData(csi = '') {
        return axios.get(`${baseUrl}/products/variants/${csi}`);
    },
    /**
     * @returns {Promise}
     */
    ometriaCart() {
        return axios.get(`${baseUrl}/ometria/cart`);
    },
};
