import dl from '~@tracking/data-layer';

export default {
    stickyToolbarLink(label) {
        dl.addTrackingEvent({
            category: 'sticky-toolbar',
            action: 'sticky_toolbar_link_selected',
            label,
        });
    },
};
