<template>
    <div class="pwa-install">
        <div
            v-if="evt && active"
            class="pwa-install__content"
        >
            <button
                class="link--btn pwa-install__close"
                @click="dismiss"
            >
                <IconGlyph name="close" />
            </button>
            <span class="pwa-install__body">
                <!-- eslint-disable -->
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1313 128" class="pwa-install__vp-logo">
                    <path d="M3.215 7.363h26.881l31.143 77.365 30.979-77.365h26.881l-46.714 114.244h-22.292z" />
                    <path d="M121.557 18.181c-0.001-0.049-0.001-0.106-0.001-0.164 0-7.694 6.238-13.932 13.932-13.932s13.932 6.238 13.932 13.932c0 7.694-6.237 13.932-13.931 13.932v0c-0.019 0-0.041 0-0.063 0-7.624 0-13.812-6.152-13.869-13.763v-0.005zM123.36 38.998h24.422v53.598c0 5.901 1.261 8.687 5.573 8.687 0.040 0.001 0.087 0.001 0.135 0.001 0.999 0 1.97-0.12 2.899-0.346l-0.084 0.017v20.816c-0.82 0.328-4.589 1.261-11.347 1.261-13.113 0-21.636-6.228-21.636-23.275v-60.76z"></path>
                    <path d="M205.314 36.375c12.293 0 23.956 3.934 34.421 15.407l-15.407 15.243c-5.043-5.409-10.49-8.523-19.013-8.523-11.801 0-21.144 9.015-21.144 21.636s9.343 21.434 21.144 21.434c8.523 0 13.932-3.114 19.013-8.523l15.407 15.243c-10.49 11.474-22.128 15.407-34.421 15.407-25.217 0-45.567-18.358-45.567-43.6s20.325-43.726 45.567-43.726z"></path>
                    <path d="M253.012 61.125h-10.818v-22.128h10.818v-31.634h23.767v31.634h18.358v22.128h-18.358v29.504c0 8.031 3.278 10.982 10.49 10.982 2.264-0.081 4.408-0.316 6.502-0.697l-0.274 0.041v21.636c-3.452 0.731-7.417 1.149-11.481 1.149-0.171 0-0.341-0.001-0.511-0.002l0.026 0c-12.129 0-28.52-3.606-28.52-29.504v-33.109z"></path>
                    <path d="M343.981 36.564c25.406 0 45.894 19.341 45.894 43.764s-20.451 43.738-45.894 43.738-45.894-19.341-45.894-43.764 20.325-43.738 45.894-43.738zM343.981 101.964c0.060 0.001 0.131 0.001 0.202 0.001 11.949 0 21.636-9.687 21.636-21.636s-9.687-21.636-21.636-21.636c-11.949 0-21.635 9.686-21.636 21.635v0c-0 0.052-0.001 0.115-0.001 0.177 0 11.838 9.596 21.434 21.434 21.434 0 0 0 0 0.001 0v0z"></path>
                    <path d="M398.563 38.998h24.422v11.347c3.783-8.359 12.608-13.113 23.956-13.113 2.304 0.048 4.516 0.225 6.689 0.525l-0.297-0.034v22.947c-2.406-0.521-5.17-0.82-8.004-0.82-0.067 0-0.134 0-0.201 0.001h0.010c-13.932 0-22.128 8.032-22.128 21.434v40.347h-24.448v-82.635z"></path>
                    <path d="M459.7 18.181c-0.001-0.049-0.001-0.106-0.001-0.164 0-7.694 6.238-13.932 13.932-13.932s13.932 6.238 13.932 13.932c0 7.694-6.237 13.932-13.931 13.932v0c-0.019 0-0.041 0-0.063 0-7.624 0-13.812-6.152-13.869-13.763v-0.005zM461.466 38.998h24.46v53.598c0 5.901 1.261 8.687 5.573 8.687 0.040 0.001 0.087 0.001 0.135 0.001 0.999 0 1.97-0.12 2.899-0.346l-0.084 0.017v20.816c-0.82 0.328-4.589 1.261-11.348 1.261-13.113 0-21.636-6.228-21.636-23.275v-60.76z"></path>
                    <path d="M537.721 37.523c12.608 0 22.292 5.043 24.586 12.293v-10.818h24.422v53.598c0 6.72 1.803 9.179 6.065 9.179 1.476-0.031 2.893-0.149 4.284-0.35l-0.186 0.022v20.325c-3.041 0.806-6.533 1.27-10.132 1.27-0.299 0-0.597-0.003-0.894-0.010l0.044 0.001c-0.328 0.014-0.713 0.022-1.099 0.022-4.516 0-8.781-1.079-12.549-2.995l0.158 0.073c-3.42-1.966-6.019-5.034-7.34-8.712l-0.036-0.114c-4.753 7.048-12.949 11.965-27.373 11.965-23.439 0-40.977-18.913-40.977-42.944 0.076-23.792 17.589-42.805 41.028-42.805zM542.159 100.955c11.965 0 21.636-9.015 21.636-20.653s-9.683-20.653-21.636-20.653c-11.406 0-20.653 9.246-20.653 20.653s9.246 20.653 20.653 20.653v0z"></path>
                    <path d="M606.563 7.363h48.189c17.374 0 38.519 13.604 38.519 38.682 0 23.111-19.669 38.682-38.519 38.682h-22.947v36.879h-25.217v-114.244zM653.277 61.781c5.043 0 14.752-3.278 14.752-15.735s-9.671-15.786-14.752-15.786h-21.434v31.521h21.434z"></path>
                    <path d="M700.81 7.363h24.422v83.593c0 7.565 2.787 10.654 9.507 10.654 0.092 0.001 0.201 0.002 0.31 0.002 1.984 0 3.912-0.24 5.756-0.692l-0.165 0.034v21.636c-3.312 0.732-7.116 1.151-11.019 1.151-0.217 0-0.435-0.001-0.652-0.004l0.033 0c-11.638 0-28.192-3.606-28.192-28.999v-87.376z"></path>
                    <path d="M745.557 38.998h24.422v47.206c0 9.507 4.917 15.571 14.26 15.571 8.826 0 15.244-6.72 15.244-16.391v-46.386h24.422v53.598c0 6.72 1.803 9.179 6.065 9.179 1.476-0.031 2.893-0.149 4.284-0.35l-0.186 0.022v20.325c-3.041 0.806-6.532 1.27-10.131 1.27-0.299 0-0.598-0.003-0.895-0.010l0.044 0.001c-0.286 0.010-0.622 0.017-0.959 0.017-4.552 0-8.848-1.097-12.638-3.040l0.156 0.073c-3.42-1.966-6.019-5.034-7.34-8.712l-0.036-0.114c-3.606 7.048-11.474 12.129-24.259 12.129-20.816 0-32.454-12.949-32.454-34.257v-50.131z"></path>
                    <path d="M840.788 38.998h24.422v10.326c3.114-7.212 11.347-12.129 21.434-12.129 11.347 0 18.686 4.59 22.456 13.113 5.409-10.326 15.735-13.113 27.209-13.113 18.686 0 28.999 12.949 28.999 34.257v20.816c0 6.065 0.984 8.687 5.409 8.687 0.984 0 2.131-0.164 2.522-0.164v20.653c-2.815 0.739-6.047 1.163-9.378 1.163-0.391 0-0.78-0.006-1.169-0.017l0.057 0.001c-12.949 0-21.8-6.228-21.8-23.603v-24.599c0-9.507-4.098-15.571-11.965-15.571s-13.441 6.72-13.441 16.391v46.399h-24.435v-47.218c0-9.507-4.262-15.571-12.293-15.571s-13.604 6.72-13.604 16.391v46.399h-24.422v-82.61z"></path>
                    <path d="M1051.146 36.375c12.293 0 23.956 3.934 34.421 15.407l-15.407 15.243c-5.043-5.409-10.49-8.523-19.013-8.523-11.801 0-21.144 9.015-21.144 21.636s9.343 21.434 21.144 21.434c8.523 0 13.932-3.114 19.013-8.523l15.42 15.281c-10.49 11.474-22.128 15.407-34.421 15.407-25.217 0-45.567-18.358-45.567-43.6s20.312-43.764 45.554-43.764z"></path>
                    <path d="M1131.461 36.564c25.406 0 45.894 19.341 45.894 43.764s-20.489 43.738-45.894 43.738-45.882-19.341-45.882-43.764 20.312-43.738 45.882-43.738zM1131.461 101.964c0.060 0.001 0.131 0.001 0.202 0.001 11.949 0 21.636-9.687 21.636-21.636s-9.687-21.636-21.636-21.636c-11.949 0-21.635 9.686-21.636 21.635v0c-0.001 0.052-0.001 0.115-0.001 0.177 0 11.838 9.596 21.434 21.434 21.434 0 0 0.001 0 0.001 0v0z"></path>
                    <path d="M1184.391 38.998h24.422v10.326c3.114-7.212 11.348-12.129 21.434-12.129 11.348 0 18.686 4.59 22.456 13.113 5.409-10.326 15.899-13.113 27.537-13.113 18.912 0 29.34 12.949 29.34 34.257v50.156h-24.422v-47.218c0-9.507-4.262-15.571-12.293-15.571s-13.768 6.72-13.768 16.391v46.399h-24.372v-47.218c0-9.507-4.262-15.571-12.293-15.571s-13.604 6.72-13.604 16.391v46.399h-24.435v-82.61z"></path>
                    <path d="M1006.374 100.791l-13.579-11.474-13.567 11.474-0.013 20.653h27.158v-20.653z"></path>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 96" width="100%" height="100%" class="pwa-install__trustpilot-stars">
                    <g id="Trustpilot_ratings_4halfstar-RGB"><g>
                            <rect fill="#00B67A" width="96" height="96"/>
                            <rect x="104" fill="#00B67A" width="96" height="96"/>
                            <rect x="208" fill="#00B67A" width="96" height="96"/>
                            <rect x="312" fill="#00B67A" width="96" height="96"/>
                            <g transform="translate(416.000000, 0.000000)">
                                <rect x="48" fill="#DCDCE6" width="48" height="96"/>
                                <rect fill="#00B67A" width="48" height="96"/>
                            </g>
                            <path fill="#FFFFFF" d="M48,64.7L62.6,61l6.1,18.8L48,64.7z M81.6,40.4H55.9L48,16.2l-7.9,24.2H14.4l20.8,15l-7.9,24.2 l20.8-15l12.8-9.2L81.6,40.4L81.6,40.4L81.6,40.4L81.6,40.4z"/>
                            <path fill="#FFFFFF" d="M152,64.7l14.6-3.7l6.1,18.8L152,64.7z M185.6,40.4h-25.7L152,16.2l-7.9,24.2h-25.7l20.8,15l-7.9,24.2 l20.8-15l12.8-9.2L185.6,40.4L185.6,40.4L185.6,40.4L185.6,40.4z"/>
                            <path fill="#FFFFFF" d="M256,64.7l14.6-3.7l6.1,18.8L256,64.7z M289.6,40.4h-25.7L256,16.2l-7.9,24.2h-25.7l20.8,15l-7.9,24.2 l20.8-15l12.8-9.2L289.6,40.4L289.6,40.4L289.6,40.4L289.6,40.4z"/>
                            <path fill="#FFFFFF" d="M360,64.7l14.6-3.7l6.1,18.8L360,64.7z M393.6,40.4h-25.7L360,16.2l-7.9,24.2h-25.7l20.8,15l-7.9,24.2 l20.8-15l12.8-9.2L393.6,40.4L393.6,40.4L393.6,40.4L393.6,40.4z"/>
                            <path fill="#FFFFFF" d="M464,64.7l14.6-3.7l6.1,18.8L464,64.7z M497.6,40.4h-25.7L464,16.2l-7.9,24.2h-25.7l20.8,15l-7.9,24.2 l20.8-15l12.8-9.2L497.6,40.4L497.6,40.4L497.6,40.4L497.6,40.4z"/>
                        </g>
                    </g>
                </svg>
                <!-- eslint-ensable -->
                <span class="pwa-install__review-count">(140k)</span>
            </span>
            <span
                class="pwa-install__btn"
                @click="install"
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="pwa-install__house-icon">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 0L15 6.88908V16H1V6.88908L8 0ZM1.99355 7.2891V15.0293H14.0065V7.2891L8 1.37783L1.99355 7.2891Z" />
<path d="M9.46397 6.33681C10.666 6.33681 11.6396 7.3226 11.6396 8.53195C11.6396 9.0222 11.451 9.5679 11.0857 10.1774C10.802 10.6505 10.4144 11.1562 9.93173 11.6904C9.58635 12.0724 9.20607 12.4535 8.80449 12.8232C8.67531 12.942 8.55022 13.0538 8.43282 13.1559C8.37209 13.2087 8.32042 13.2527 8.28778 13.2796C8.12914 13.4112 7.89753 13.4112 7.73889 13.2796C7.70626 13.2527 7.65459 13.2087 7.59385 13.1559C7.47646 13.0538 7.35136 12.942 7.22219 12.8232C6.82061 12.4535 6.44033 12.0724 6.09495 11.6904C5.61224 11.1566 5.22471 10.651 4.94097 10.1774C4.57565 9.5679 4.3871 9.0222 4.3871 8.53195C4.3871 7.3226 5.36068 6.33681 6.56271 6.33681C7.09845 6.33681 7.61334 6.54321 8.01311 6.90628C8.41288 6.54321 8.92823 6.33681 9.46397 6.33681ZM8.22252 12.2075C8.60551 11.855 8.96766 11.4915 9.29491 11.1298C9.74182 10.6355 10.0976 10.1713 10.3514 9.74834C10.6406 9.26601 10.7866 8.85365 10.7866 8.53195C10.7866 7.78336 10.1905 7.17825 9.46397 7.17825C9.0361 7.17825 8.625 7.40489 8.36755 7.78292C8.19849 8.03113 7.82819 8.03113 7.65912 7.78292C7.40213 7.40489 6.99058 7.17825 6.56271 7.17825C5.8366 7.17825 5.24012 7.78292 5.24012 8.53195C5.24012 8.85365 5.38606 9.26645 5.67524 9.74834C5.92906 10.1713 6.28441 10.6351 6.73177 11.1298C7.05902 11.492 7.42162 11.855 7.80416 12.2075C7.87532 12.2731 7.94512 12.3365 8.01311 12.3968C8.08065 12.3365 8.15135 12.2731 8.22252 12.2075Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.28778 13.2796C8.32042 13.2527 8.37209 13.2087 8.43282 13.1559C8.55022 13.0538 8.67531 12.942 8.80449 12.8232C9.20607 12.4535 9.58635 12.0724 9.93173 11.6904C10.4144 11.1562 10.802 10.6505 11.0857 10.1774C11.451 9.5679 11.6396 9.0222 11.6396 8.53195C11.6396 7.3226 10.666 6.33681 9.46397 6.33681C8.92823 6.33681 8.41288 6.54321 8.01311 6.90628C7.61334 6.54321 7.09845 6.33681 6.56271 6.33681C5.36068 6.33681 4.3871 7.3226 4.3871 8.53195C4.3871 9.0222 4.57565 9.5679 4.94097 10.1774C5.22471 10.651 5.61224 11.1566 6.09495 11.6904C6.44033 12.0724 6.82061 12.4535 7.22219 12.8232C7.35136 12.942 7.47646 13.0538 7.59385 13.1559C7.65459 13.2087 7.70626 13.2527 7.73889 13.2796C7.81633 13.3438 7.91116 13.3767 8.0064 13.3782C8.01201 13.3783 8.01762 13.3783 8.02322 13.3781C8.11747 13.376 8.21114 13.3431 8.28778 13.2796ZM8.36755 7.78292C8.19849 8.03113 7.82819 8.03113 7.65912 7.78292C7.40213 7.40489 6.99058 7.17825 6.56271 7.17825C5.8366 7.17825 5.24012 7.78292 5.24012 8.53195C5.24012 8.85365 5.38606 9.26645 5.67524 9.74834C5.92906 10.1713 6.28441 10.6351 6.73177 11.1298C7.05902 11.492 7.42162 11.855 7.80416 12.2075C7.87532 12.2731 7.94512 12.3365 8.01311 12.3968C8.08065 12.3365 8.15135 12.2731 8.22252 12.2075C8.60551 11.855 8.96766 11.4915 9.29491 11.1298C9.74182 10.6355 10.0976 10.1713 10.3514 9.74834C10.6406 9.26601 10.7866 8.85365 10.7866 8.53195C10.7866 7.78336 10.1905 7.17825 9.46397 7.17825C9.0361 7.17825 8.625 7.40489 8.36755 7.78292Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.20645 8.53195C4.20645 7.2307 5.2553 6.16031 6.56271 6.16031C7.09247 6.16031 7.60117 6.34553 8.01312 6.67254C8.42516 6.34551 8.93424 6.16031 9.46397 6.16031C10.7714 6.16031 11.8202 7.2307 11.8202 8.53195C11.8202 9.06556 11.6155 9.64273 11.2416 10.2666C10.9503 10.7522 10.5551 11.2672 10.0672 11.8072C9.71776 12.1937 9.33355 12.5787 8.92837 12.9517C8.798 13.0716 8.67161 13.1845 8.55293 13.2877C8.492 13.3407 8.43906 13.3858 8.40455 13.4142C8.3826 13.4324 8.35967 13.4489 8.33595 13.4635C8.26043 13.5102 8.17687 13.5389 8.09144 13.5498C8.07019 13.5525 8.04882 13.5541 8.02744 13.5546C8.01942 13.5548 8.01142 13.5548 8.00345 13.5547C7.99805 13.5546 7.99265 13.5544 7.98725 13.5542C7.96552 13.5533 7.94382 13.5512 7.92226 13.548C7.83177 13.5346 7.74373 13.5009 7.66554 13.4471C7.65075 13.4369 7.63631 13.426 7.62227 13.4144C7.58775 13.386 7.53477 13.3408 7.47376 13.2878C7.35508 13.1845 7.22873 13.0716 7.09836 12.9517C6.69318 12.5787 6.30896 12.1937 5.95957 11.8073C5.47159 11.2676 5.07634 10.7527 4.78508 10.2666C4.41115 9.64272 4.20645 9.06555 4.20645 8.53195ZM5.05947 8.53195C5.05947 7.69276 5.7295 7.00175 6.56271 7.00175C7.05678 7.00175 7.52234 7.26281 7.80951 7.68514C7.90708 7.82839 8.11958 7.8284 8.21716 7.68515C8.5047 7.26293 8.96976 7.00175 9.46397 7.00175C10.2977 7.00175 10.9672 7.69328 10.9672 8.53195C10.9672 8.89994 10.8027 9.34489 10.5073 9.83755C10.2459 10.2731 9.88232 10.7467 9.43029 11.2466C9.09912 11.6127 8.73309 11.9801 8.34646 12.3359C8.2742 12.4025 8.2026 12.4667 8.13497 12.5271L8.01352 12.6355L7.8917 12.5275C7.82266 12.4662 7.75199 12.4021 7.68022 12.3359C7.29418 11.9802 6.92767 11.6133 6.59634 11.2466C6.14387 10.7463 5.7808 10.2731 5.51941 9.83762C5.22398 9.34531 5.05947 8.89992 5.05947 8.53195Z" />
</svg>
                Get the app
            </span>
        </div>
    </div>
</template>

<script>
import IconGlyph from '~@common/icon/IconGlyph';

export default {
    name: 'PwaInstall',
    components: {
        IconGlyph,
    },
    props: {
        evt: {
            type: Event,
            required: false,
            default: () => null,
        },
    },
    data() {
        return {
            active: true,
        };
    },
    methods: {
        close() {
            this.active = false;
            this.$emit('close');
        },
        dismiss() {
            this.close();
        },
        async install() {
            this.close();
            this.evt.prompt();
        },
    },
};
</script>

<style scoped>
.pwa-install__content {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 8px;
    background: var(--purple-100);
    display: grid;
    grid-template-columns: 48px 1fr 1fr;
    grid-column-gap: 8px;
    align-items: center;
    justify-items: stretch;
    z-index: var(--z-index-popups);
    box-shadow: 0 -1px 6px 3px rgba(0, 0, 0, 0.5);
}

.pwa-install__close .icon-glyph {
    fill: var(--white);
    width: 24px;
    height: 24px;
}

.pwa-install__content {
    color: var(--white);
}

.pwa-install__vp-logo {
    width: 120px;
    fill: var(--white);
    display: block;
}

.pwa-install__trustpilot-stars {
    width: 80px;
    transform: translateY(4px);
}

.pwa-install__review-count {
    font-size: 12px;
}

.pwa-install__btn {
    color: var(--purple-100);
    background: var(--white);
    padding: 12px 8px;
    justify-self: end;
    border-radius: var(--default-border-radius);
    display: flex;
    align-items: center;
    font-size: 14px;
}

.pwa-install__house-icon {
    fill: var(--purple-100);
    margin-right: 4px;
    width: 16px;
    height: 16px;
}

@media (min-width: 360px) {
    .pwa-install__content {
        grid-template-columns: 48px 1fr 1fr;
        column-gap: 12px;
        font-size: 16px;
    }

    .pwa-install__vp-logo {
        width: 150px;
    }

    .pwa-install__trustpilot-stars {
        width: 110px;
    }
}

@media (min-width: 460px) {
    .pwa-install__btn {
        padding: 12px;
    }
}
</style>
