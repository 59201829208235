/**
 * @param {HTMLElement} element
 * @returns {number}
 */
function getHeight(element) {
    const styles = window.getComputedStyle(element);
    const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

    return Math.ceil(element.offsetHeight + margin);
}

/**
 *
 * @param {HTMLElement} accordion
 * @param {number} height
 */
function setHeight(accordion, height) {
    const container = accordion.querySelector('.accordion-container');
    container.style.maxHeight = `${height}px`;
}

/**
 * @param {HTMLElement} accordion
 */
function closeAccordion(accordion) {
    accordion.classList.remove('is-active');
    setHeight(accordion, 0);
}

/**
 * @param {HTMLElement} accordion
 */
function openAccordion(accordion) {
    accordion.classList.add('is-active');
    setHeight(accordion, getHeight(accordion.querySelector('.accordion-list')));
}

/**
 * @param {Event} event
 */
export default function accordionHandler(event) {
    const accordionItem = event.currentTarget.parentNode;

    if (accordionItem.classList.contains('is-active')) {
        closeAccordion(accordionItem);
    } else {
        openAccordion(accordionItem);
    }
}
