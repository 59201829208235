/**
 * Get user type from victoriaplum global
 *
 * @type {string}
 */
const user = {
    group: 'retail',
    type: 'visitor',
    agent: false,
    isTrade() {
        return this.group.indexOf('trade') !== -1;
    },
    isLoggedIn() {
        return this.type !== 'visitor';
    },
    isAgent() {
        return this.agent;
    },
};

if ('victoriaplum' in window && 'user' in window.victoriaplum) {
    user.group = window.victoriaplum.user.user_group;
    user.type = window.victoriaplum.user.user_type;
    user.agent = window.victoriaplum.user.is_agent;
}

export default user;
