import dl from '~@tracking/data-layer';

export default {
    signup() {
        dl.addTrackingEvent({
            category: 'newsletter',
            action: 'signup',
            label: '',
        });

        dl.addEcommerceEvent({
            event: 'generate_lead',
        });
    },
};
