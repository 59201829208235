import Vue from 'vue';
import ReviewStars from '../../../vue/component/product-detail/panels/reviews/ReviewStars';

/**
 * initialiseReviewStars
 *
 * @param {string} selector
 */
function initialiseReviewStars(selector) {
    [...document.querySelectorAll(selector)].forEach((reviewStar) => {
        const averageRating = reviewStar.getAttribute('data-average-rating');
        const count = reviewStar.getAttribute('data-count');
        const size = reviewStar.getAttribute('data-size');

        new Vue({
            el: reviewStar,
            components: {
                ReviewStars,
            },
            mounted() {
                this.$forceUpdate();
            },
            template: `<ReviewStars :rating="${averageRating}" :count="${count}" size="${size}" />`,
        });
    });
}

export default initialiseReviewStars;
