import axios from 'axios';
import formData from '~@helpers/form-data';
import copyTextToClipboard from '~@helpers/click-to-copy';
import newsletterTracking from '~@tracking/newsletter';
import '~@listeners/marketing-opt-in';

/**
 * @param {object} data
 * @param {HTMLElement} formElem
 */
function success(data, formElem) {
    const container = formElem.closest('[data-marketing-subscribe="container"]');
    const link = document.querySelector('.i-change-text');
    const successMsg = document.createElement('div');

    successMsg.innerHTML = data.data;

    newsletterTracking.signup();

    if (container) {
        container.replaceWith(successMsg);

        [...document.querySelectorAll('.i-copy-text')].forEach((el) => {
            el.addEventListener('click', () => {
                copyTextToClipboard(el);
            }, false);
        });
    }

    if (link) {
        link.innerHTML = 'Click here to close';
    }
}

/**
 * @param {string} message
 * @param {HTMLElement} formElem
 */
// eslint-disable-next-line consistent-return
function error(message, formElem) {
    const input = formElem.querySelector('[data-marketing-subscribe="input"]');
    const inputWrapper = input.parentNode;

    // if error already shown, do not append duplicate message
    if (inputWrapper.classList.contains('inputs--error')) {
        inputWrapper.querySelector('.form__text').textContent = message;
    } else {
        const errorfield = document.createElement('p');

        errorfield.setAttribute('class', 'text form__text');
        errorfield.textContent = message;

        inputWrapper.classList.add('inputs--error');
        inputWrapper.appendChild(errorfield);
    }
}

const marketingForm = document.querySelector('[data-marketing-subscribe="form"]');

if (marketingForm) {
    marketingForm.addEventListener('submit', (event) => {
        event.preventDefault();

        const form = formData.create(marketingForm);
        const data = formData.serialiseForm(form);
        const rq = formData.request('/newsletter', data);

        axios.post(rq.url)
            .then((response) => {
                success(response, marketingForm);
            })
            .catch((response) => {
                const errors = response.response.data;

                let message = 'Something went wrong, please try again.';

                if (typeof errors.subscribe_email !== 'undefined' && typeof errors.subscribe_email[0] !== 'undefined') {
                    // eslint-disable-next-line prefer-destructuring
                    message = errors.subscribe_email[0];
                }

                error(message, marketingForm);
            });
    });
}
