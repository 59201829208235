<template>
    <transition name="fade">
        <div
            v-if="active"
            :id="id"
            class="modal"
        >
            <div
                v-click-outside="close"
                class="modal__content"
            >
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import '~@directives/click-outside';
import { lockViewport, unlockViewport } from '~@helpers/viewport';

export default {
    name: 'Modal',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: '',
        },
    },
    watch: {
        active(isActive) {
            if (isActive) {
                lockViewport();
            } else {
                unlockViewport();
            }
        },
    },
    mounted() {
        if (this.active) {
            lockViewport();
        }
    },
    methods: {
        close() {
            this.$emit('closeModal');
        },
    },
};
</script>

<style scoped>
    :root {
        --modal-layer-above: calc(var(--z-index-modals) + 1);
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        overflow-x: hidden;
        z-index: var(--modal-layer-above);

        @add-mixin mobile-only {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    .modal__content {
        background: var(--white);
        overflow: hidden;
        max-width: var(--desktop-width);

        @add-mixin mobile-only {
            @supports (-webkit-overflow-scrolling: touch) {
                padding-bottom: calc(44px + var(--gutter-default)); /* height of iOS toolbar, plus normal gutter */
            }
        }

        @add-mixin tablet-portrait {
            margin: 16px auto 0;
        }
    }

    .modal--small .modal__content {
        max-width: 625px;
        margin: 0 16px;

        @add-mixin tablet-portrait {
            margin: 75px auto;
        }

        @add-mixin mobile-only {
            @supports (-webkit-overflow-scrolling: touch) {
                padding-bottom: inherit;
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s ease-in-out;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
