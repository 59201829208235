import dl from '~@tracking/data-layer';

export default {
    installed(label) {
        dl.addTrackingEvent({
            category: 'pwa',
            action: 'installed',
            label,
        });
    },

};
