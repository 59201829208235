/**
 * @param {HTMLElement} formElement
 * @returns {FormData}
 */
function create(formElement) {
    return new FormData(formElement);
}

/**
 * Serialize the form data to an array ala jQuery
 *
 * @param {FormData} formData
 * @returns {Array}
 */
function serialiseForm(formData) {
    return Array.from(formData.entries(), elem => ({ name: elem[0], value: elem[1] }));
}

/**
 * Build the URL with the correct params
 *
 * @param {string} baseUrl
 * @param {object} params
 * @returns {*}
 */
function urlWithParams(baseUrl, params) {
    const searchParams = new URLSearchParams();

    params.forEach(param => searchParams.append(param.name, param.value || ''));
    return `${baseUrl}?${searchParams.toString()}`;
}

/**
 * Build the url and make sure we perform the request with XMLHttpRequest header
 * Otherwise we will get a 400 for a non ajax request.
 *
 * @param {string} action
 * @param {object} data
 * @returns {Request}
 */
function request(action, data) {
    return new Request(urlWithParams(action, data), {
        method: 'GET',
        headers: new Headers({ 'X-Requested-With': 'XMLHttpRequest' }),
        credentials: 'same-origin',
    });
}

export default {
    serialiseForm,
    request,
    create,
};
