/**
 * scrollPosition
 *
 * @type {number}
 */
let scrollPosition = 0;

/**
 * html
 *
 * @type {Element}
 */
const html = document.querySelector('html');

/**
 * body
 *
 * @type {Element}
 */
const body = document.querySelector('body');

/**
 * setViewport
 */
function setViewport() {
    if (scrollPosition > 0) {
        document.documentElement.scrollTop = scrollPosition;
        document.body.scrollTop = scrollPosition;
    }
}

/**
 * Get the viewport dimensions
 *
 * @returns {{width: number, height: number}}
 */
export function viewportDimensions() {
    return {
        width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    };
}

/**
 * @param {HTMLElement} element
 * @returns {boolean}
 */
export function elementInView(element) {
    const { top, height } = element.getBoundingClientRect();
    return (top + height) <= viewportDimensions().height;
}

export function lockViewport() {
    scrollPosition = window.pageYOffset;
    html.classList.add('disable-scroll');
    body.classList.add('disable-scroll');

    /*
    * Double rAF used to fix iOS >= 12 keyboard issue
    * (open keyboard causes viewport to shift up)
    * https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
    */
    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        });
    });
}

export function unlockViewport() {
    html.classList.remove('disable-scroll');
    body.classList.remove('disable-scroll');
    setViewport();
    scrollPosition = 0;
}
