/**
 * An overlay describes any active component
 * that has taken over the viewport layer
 * E.g. modal, search tray, off canvas menu etc
 *
 * The mediator allows callbacks to be added to a queue which is called before
 * another component opens. This is to avoid any instances where two components
 * are active at the same time.
 */
class OverlayMediator {
    constructor() {
        this.queue = [];
    }

    /**
     * @param {object} obj
     */
    addToQueue(obj) {
        this.queue.push(obj);
    }

    /**
     * @param {string} id
     * @returns {boolean}
     */
    existsOnQueue(id) {
        return !!this.queue.find(item => item.id === id);
    }

    /**
     * @returns {boolean}
     */
    queueIsEmpty() {
        return this.queue.length === 0;
    }

    executeCallbacks() {
        this.queue.forEach((item) => {
            if (item.fnc instanceof Function) {
                item.fnc();
            }
        });
    }

    /**
     * @param {string} moduleId
     * @param {Function} callback
     */
    register(moduleId, callback) {
        if (this.existsOnQueue(moduleId)) {
            return;
        }

        if (this.queue.length) {
            this.executeCallbacks();
        }

        this.addToQueue({ id: moduleId, fnc: callback });
    }

    /**
     * @param {string} moduleId
     */
    unregister(moduleId) {
        this.queue = this.queue.filter((item) => item.id !== moduleId);
    }
}

export default new OverlayMediator();
