import Vue from 'vue';

const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
            if (!(event.target.hasAttribute('data-ignore-click-outside')
                || event.target.parentNode.hasAttribute('data-ignore-click-outside'))
                && !(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };

        document.body.addEventListener(clickHandler, el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener(clickHandler, el.clickOutsideEvent);
    },
});
