import megaNavTracking from '~@tracking/mega-nav';
import overlayMediator from '../helpers/overlay-mediator';
import keyCode from '../helpers/keycode';
import Backdrop from './backdrop';
import sticky from '../helpers/sticky';

const megaNav = document.querySelector('.i-mega-nav');
const container = document.querySelector('.i-mega-nav-container');
const links = megaNav.querySelectorAll('.i-mega-nav-link');
const trackingLinks = megaNav.querySelectorAll('.i-track-mega-nav-link');
const menus = megaNav.querySelectorAll('.i-mega-nav-menu');
const offersLink = megaNav.querySelector('.i-mega-nav-offers-link');
const subGroups = megaNav.querySelectorAll('.i-track-grouplink');
const mappedMenus = new Map();
const DELAY_MILLISECONDS = 250;
const MOUSE_LOCATIONS_TRACKED = 3;
const CATEGORY_TRACKING_DELAY = 1000;
const mouseLocations = [];
const moduleId = 'mega-nav';

let activeLink = null;
let activePanel = null;
let navIsVisible = false;
let hideDelay = null;
let lastDelayLoc = null;
let activateDelay = null;
let categoryTrackingTimer = null;

/**
 * The Menu would obscure the quick search
 * Query the DOM to find out if the quick search is open
 *
 * @returns {Element}
 */
function quickSearchIsActive() {
    return document.querySelector('.i-search-tray.is-active');
}

/**
 * Clear all active states
 */
function clearActive() {
    megaNav.classList.remove('is-active');

    if (activePanel) {
        activePanel.classList.remove('is-active');
        activePanel = null;
    }

    if (activeLink) {
        activeLink.classList.remove('is-active');
        activeLink = null;
    }
}

/**
 * @param {string} category
 */
function trackMenuCategory(category) {
    window.clearTimeout(categoryTrackingTimer);
    categoryTrackingTimer = window.setTimeout(
        () => megaNavTracking.trackCategory(category),
        CATEGORY_TRACKING_DELAY,
    );
}

/**
 * If quick search is not in use ...
 * Clear any active links and open the current one using the
 * mapped menus.
 *
 * @param {HTMLElement} link
 */
function activateMenu(link) {
    if (quickSearchIsActive()) {
        return;
    }

    if (!navIsVisible) {
        megaNavTracking.openMenu();
    }

    showNav();
    clearActive();
    megaNav.classList.add('is-active');
    link.classList.add('is-active');
    activeLink = link;

    const target = mappedMenus.get(link.getAttribute('data-target'));
    const category = link.getAttribute('data-link-title');

    if (target) {
        target.classList.add('is-active');
        activePanel = target;
        trackMenuCategory(category);
    }

    container.addEventListener('mouseleave', delayHideNav);
}

/**
 * Exit the menu and return to the main navigation menu
 * keycodes: 27 = escape, 38 = up
 *
 * @param {Event} ev
 */
function blurMeganav(ev) {
    const key = ev.keyCode;

    if (key === keyCode.escape || key === keyCode.up) {
        ev.preventDefault();

        if (activeLink) {
            activeLink.focus();
            clearActive();
            document.removeEventListener('keydown', blurMeganav);
        }

        if (navIsVisible) {
            hideNav();
        }
    }
}

/**
 * Allow keyboard navigation to open the menu
 * keycodes: 13 = enter, 32 = space, 40 = down, 27 = esc
 *
 * @param {HTMLElement} link
 * @param {Event} ev
 */
function focusMeganav(link, ev) {
    const key = ev.keyCode;

    document.addEventListener('keydown', blurMeganav);
    activeLink = link;

    if (key === keyCode.enter || key === keyCode.down || key === keyCode.space) {
        ev.preventDefault();

        // If search is open and we are using the nav, close search
        if (quickSearchIsActive()) {
            overlayMediator.executeCallbacks();
            window.requestAnimationFrame(() => focusMeganav(link, ev));
            return;
        }

        if (!navIsVisible) {
            showNav();
        }

        activateMenu(link);

        // On down arrow keypress open the meganv and focus on the first link
        if (key === keyCode.down) {
            const firstLink = activePanel.querySelector('a');
            if (firstLink) {
                firstLink.focus();
                document.addEventListener('keydown', blurMeganav);
            }
        }
    }
}

/**
 * Show the mega nav, only if the quick search is not in use
 */
function showNav() {
    if (quickSearchIsActive() || navIsVisible) {
        return;
    }

    overlayMediator.register(moduleId, hideNav);
    navIsVisible = true;
    Backdrop.show();
}

/**
 * Don't close the menu immediately on mouse leave
 */
function delayHideNav() {
    hideDelay = window.setTimeout(hideNav, DELAY_MILLISECONDS);
}

/**
 * Hide the mega nav and clear all active states, including the backdrop overlay
 */
function hideNav() {
    if (!navIsVisible) {
        return;
    }

    overlayMediator.unregister(moduleId);
    clearActive();
    navIsVisible = false;
    Backdrop.hide();
    container.removeEventListener('mouseleave', delayHideNav);
}

/**
 * Guess if we are mousing over to get to the submenu
 * and return a delay to give us chance to ignore it if we have to
 *
 * Taken from jQuery aim
 * https://github.com/kamens/jQuery-menu-aim/blob/master/jquery.menu-aim.js
 *
 * @returns {number}
 */
function activationDelay() {
    const loc = mouseLocations[mouseLocations.length - 1];
    const prevLoc = mouseLocations[0];

    // Prevent instant drop down to stop flicker on mouse through menu
    if (!navIsVisible && !lastDelayLoc) {
        lastDelayLoc = loc;
        return DELAY_MILLISECONDS;
    }

    // Open instantly if we have no other active link open
    // or we are coming back up from the submenu react instantly
    if (!activeLink || !loc || !prevLoc || loc.y <= prevLoc.y) {
        return 0;
    }

    // If the mouse is static on the 'nexttick' open instantly, the delay has passed
    if (lastDelayLoc
        && loc.x === lastDelayLoc.x && loc.y === lastDelayLoc.y) {
        return 0;
    }

    const offset = container.getBoundingClientRect();
    const lowerLeft = {
        x: offset.left,
        y: offset.top + offset.height,
    };
    const lowerRight = {
        x: offset.left + offset.width,
        y: lowerLeft.y,
    };

    /**
     * @param {object} a
     * @param {object} b
     * @returns {number}
     */
    function slope(a, b) {
        return (b.y - a.y) / (b.x - a.x);
    }

    const decrease = slope(loc, lowerRight);
    const increase = slope(loc, lowerLeft);
    const prevDecrease = slope(prevLoc, lowerRight);
    const prevIncrease = slope(prevLoc, lowerLeft);

    if (decrease < prevDecrease
        && increase > prevIncrease) {
        lastDelayLoc = loc;
        return DELAY_MILLISECONDS;
    }

    return 0;
}

/**
 * Delay the activation event based on hover intent
 *
 * @param {HTMLElement} link
 */
function possiblyActivate(link) {
    const delay = activationDelay();

    if (delay) {
        activateDelay = setTimeout(() => {
            possiblyActivate(link);
        }, delay);
    } else {
        lastDelayLoc = null;
        activateMenu(link);
    }
}

/**
 * Track mouse movement to allow us to work out hover intent
 *
 * @param {Event} e
 */
function mousemoveDocument(e) {
    mouseLocations.push({ x: e.pageX, y: e.pageY });

    if (mouseLocations.length > MOUSE_LOCATIONS_TRACKED) {
        mouseLocations.shift();
    }
}

/**
 * Hide the nav if it is open
 * Add sticky nav if the window width changes to match 1024px (device rotations)
 */
function handleOrientationChange() {
    window.matchMedia('(min-width: 1024px)').addListener((e) => {
        if (e.matches) {
            sticky(megaNav, 'body', 'sticky-nav');
        } else {
            hideNav();
        }
    });
}

document.addEventListener('mousemove', mousemoveDocument);

if (megaNav && container) {
    // Make the mega-nav sticky
    sticky(megaNav, 'body', 'sticky-nav');
    handleOrientationChange();

    // Create a Map to store a reference from link to menu
    [...menus].forEach((menu) => {
        const key = menu.getAttribute('data-target');
        mappedMenus.set(key, menu);
    });

    // Clear the hidenav delay stop the menu closing early
    container.addEventListener('mousemove', () => {
        clearTimeout(hideDelay);
    });

    container.addEventListener('mouseleave', () => {
        clearTimeout(activateDelay);
    });

    // Prevent the links from navigating on touch and show the correct menu
    // If the menu is already active, allow navigation
    [...links].forEach((link) => {
        link.addEventListener('touchend', (e) => {
            if (!quickSearchIsActive() && activeLink !== link) {
                e.preventDefault();
                e.stopPropagation();
                activateMenu(link);
            }
        }, true);

        // Enable hover for mouse users
        link.addEventListener('mouseenter', () => possiblyActivate(link), true);
        link.addEventListener('mouseleave', () => {
            clearTimeout(activateDelay);
            lastDelayLoc = null;
        });

        link.addEventListener('keydown', ev => focusMeganav(link, ev), true);
    });

    [...trackingLinks].forEach((link) => {
        // Add tracking event listener for top-nav links
        link.addEventListener('click', () => {
            megaNavTracking.itemLink(link.pathname);
        });
    });

    [...subGroups].forEach((groupElem) => {
        groupElem.addEventListener('click', () => {
            megaNavTracking.subGroup(groupElem.dataset.sectionHeading, groupElem.dataset.sectionTitle);
        });
    });

    // There is no drop down - so hide the mega-nav
    if (offersLink) {
        offersLink.addEventListener('mouseenter', () => hideNav(), true);
    }
}
