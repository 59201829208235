import ActivatableItem from './activatable-item';
import ActivatableCollection from './activatable-collection';

/**
 * A collection of panel views (eg. off canvas panels)
 */
export default class PanelViews extends ActivatableCollection {
    /**
     * Takes the parent element (eg. '.i-panel-views'), parent should contain
     * DOMElements with a class of '.i-panel-view' and a data-name attribute,
     * and triggers with a class of '.i-show-panel-view'. Triggers should have
     * a data-target attribute that corresponds to a view name
     *
     * @param {object} panel
     */
    constructor(panel) {
        super();

        this.map = {};
        this.triggers = new WeakMap();
        this.actions = { before: [], after: [] };
        this.panel = panel;

        [...panel.element.querySelectorAll('.i-panel-view')].forEach(elem => this.addView(elem));
        [...panel.element.querySelectorAll('.i-show-panel-view')].forEach(elem => this.addTrigger(elem));
    }

    /**
     * User functions to execute before changing view
     *
     * @param {Function} fn
     */
    onBeforeChange(fn) {
        this.actions.before.push(fn);
    }

    /**
     * User functions to execute after changing view
     *
     * @param {Function} fn
     */
    onAfterChange(fn) {
        this.actions.after.push(fn);
    }

    /**
     * Add a new view to the collection
     *
     * @param {HTMLElement} element
     */
    addView(element) {
        const panelView = new ActivatableItem(element, element.classList.contains('is-active'));
        const name = element.getAttribute('data-name');
        this.addItem(panelView);

        if (name) {
            this.map[name] = panelView;
        }
    }

    /**
     * A set of triggers that control the views
     * Creates a Weakmap to minimise queries on the DOMElement
     *
     * @param {HTMLElement} element
     */
    addTrigger(element) {
        const target = element.getAttribute('data-target');

        if (target) {
            this.triggers.set(element, target);
        }

        element.addEventListener('click', (e) => {
            e.preventDefault();
            this.show(this.triggers.get(element));
        });
    }

    /**
     * The name of the view to show
     * Closes any active views first
     *
     * @param {string} name
     */
    show(name) {
        const view = this.viewByName(name);

        if (view) {
            this.actions.before.forEach(fn => fn(name));
            this.hideAll();
            view.setActive();
            this.actions.after.forEach(fn => fn(name));
        } else {
            this.panel.close();
        }
    }

    /**
     * Hide Views
     */
    hideAll() {
        this.all().forEach((view) => {
            view.setInactive();
        });
    }

    /**
     * Get a view by its name (derived from the data-name attribute)
     *
     * @param {string} name
     *
     * @returns {*}
     */
    viewByName(name) {
        return this.map[name];
    }
}
