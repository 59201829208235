import loadBundle from '~@helpers/dynamic-bundles';
import lazyBackgroundImage from '~@helpers/lazy-background-image';

/**
 *
 * @param {HTMLImageElement} image
 */
function handleError(image) {
    image.classList.add('lazyerror');
    image.title = 'Image missing or failed to load';
    image.src = image.dataset.placeholder;
}

/**
 *
 * @param {HTMLElement} elem
 */
export default async function lazyLoadImages(elem = document) {
    const images = [...elem.querySelectorAll('img[loading="lazy"]:not(.loaded)')];
    const pictures = [...elem.querySelectorAll('[data-lazy-picture]')];
    const backgroundImages = [...elem.querySelectorAll('[data-lazy-background]')];

    backgroundImages.forEach((bgElem) => {
        lazyBackgroundImage(bgElem);
    });

    if ('loading' in HTMLImageElement.prototype) {
        pictures.forEach((picture) => {
            [...picture.querySelectorAll('source')].forEach((source) => {
                source.srcset = source.dataset.srcset;
            });
        });

        images.forEach((image) => {
            image.onload = () => { image.classList.add('lazyloaded'); };
            image.onerror = () => { handleError(image); };
            image.src = image.dataset.src;
        });
    } else {
        if (!('HTMLPictureElement' in window)) {
            await loadBundle('picturefill');
        }

        images.forEach((image) => {
            image.onerror = () => { handleError(image); };
            image.classList.add('lazy-js');
        });

        await loadBundle('lazysizes');
    }
}
