import passiveIfSupported from '~@helpers/passive-supported';
import { lockViewport, unlockViewport } from '~@helpers/viewport';
import ThreeSixty from '~@page/product-detail/three-sixty';
import loadBundle from '~@helpers/dynamic-bundles';

let is360Loading = false;

/**
 * @param {HTMLElement} gallery
 * @returns {*}
 */
function register360(gallery) {
    const container = gallery.currItem.container.querySelector('.i-360');

    if (!container) {
        return {};
    }

    ['MSPointerDown', 'mousedown', 'touchstart', 'pointerdown'].forEach((type) => {
        container.addEventListener(type, (event) => {
            if (event.target === container && !is360Loading) {
                gallery.close();
            }
        }, passiveIfSupported);
    });

    return new ThreeSixty(container, 4, 12);
}

/**
 * @param {NodeList} buttons
 * @param {boolean} state
 */
function setLoadingState(buttons, state) {
    is360Loading = state;
    [...buttons].forEach((button) => {
        if (state) {
            button.classList.add('pswp__element--disabled');
        } else {
            button.classList.remove('pswp__element--disabled');
        }
    });
}

/**
 * @param {Array} defaultImageUrls
 * @returns {Array}
 */
function build360Items(defaultImageUrls) {
    const items = [];

    defaultImageUrls.forEach((uri) => {
        items.push({
            html: `<div class="i-360 gallery-launchers__360">
                        <img src="${uri}"  alt="360 Image">
                        <button class="btn btn--lg btn--secondary i-start-360">
                            Tap to start 360
                            <br>swipe to rotate
                        </button>
                    </div>`,
        });
        const preload = new Image();
        preload.src = uri;
    });

    return items;
}

/**
 * @param {object} gallery
 */
function handleActive360(gallery) {
    handleSwipe(gallery);
    const galleryPrevNext = document.querySelectorAll('.pswp__button--arrow--left, .pswp__button--arrow--right');

    setLoadingState(galleryPrevNext, true);

    let active360 = register360(gallery);

    active360.loaded.then((resp) => {
        if (resp instanceof Error) {
            handle360Error(resp);
            gallery.close();
        }

        setLoadingState(galleryPrevNext, false);
    });

    gallery.listen('beforeChange', () => {
        handleSwipe(gallery);
    });

    gallery.listen('afterChange', () => {
        try {
            setLoadingState(galleryPrevNext, true);
            active360.stop();
        } catch (e) {
            // we should try to stop the last 360,
            // but if it failed to initialise we can ignore the resulting error here
        }

        active360 = register360(gallery);

        active360.loaded.then((resp) => {
            if (resp instanceof Error) {
                handle360Error(resp);
                gallery.close();
            }

            setLoadingState(galleryPrevNext, false);
        });

        handleSwipe(gallery);
    });
}

/**
 * Handles photoswipe swipe.
 *
 * @param {object} gallery
 */
function handleSwipe(gallery) {
    const start360 = gallery.currItem.container.querySelector('.i-start-360');
    const container = gallery.currItem.container.querySelector('.i-360');

    ['click', 'touchend'].forEach((type) => {
        start360.addEventListener(type, () => {
            disableSwipe(container);
        });
    });
}

/**
 * Disables photoswipe swipe.
 *
 * @param {HTMLElement} container
 */
function disableSwipe(container) {
    ['MSPointerDown', 'mousedown', 'touchstart', 'pointerdown'].forEach((type) => {
        container.addEventListener(type, (event) => {
            event.preventDefault();
            event.stopPropagation();
        });
    });
}

function handle360Error() {
    const alertElem = document.createElement('div');

    alertElem.setAttribute('class', 'alert alert--fixed i-alert');
    alertElem.innerHTML = "Sorry, we couldn't find the 360 images for this product.";

    document.body.appendChild(alertElem);

    setTimeout(() => {
        const alert = document.querySelector('.i-alert');

        if (alert) {
            alert.parentNode.removeChild(alert);
        }
    }, 5000);
}

/**
 * Initialises a new PhotoSwipe Gallery
 *
 * @param {HTMLElement} button
 * @param {Array} items
 * @param {object} options
 * @returns {null|{w: number, x: number, y: number}}
 */
export async function openPhotoSwipe(button, items, options = {}) {
    const pswpElement = document.querySelector('.pswp');

    if (!pswpElement) {
        console.error('Failed to find PhotoSwipe element on the page.'); // eslint-disable-line no-console
        return null;
    }

    const defaultOptions = {
        getThumbBoundsFn() {
            const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
            const rect = button.getBoundingClientRect();

            return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        },
        index: 0,
        showHideOpacity: true,
        bgOpacity: 0.9,
        closeOnScroll: false,
        pinchToClose: false,
    };

    const galleryOptions = Object.assign(defaultOptions, options);

    const { default: PhotoSwipe } = await loadBundle('photoswipe');
    const { default: PhotoSwipeUIDefault } = await loadBundle('photoswipe-ui');

    const largeGallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault, items, galleryOptions);
    largeGallery.init();

    return Promise.resolve(largeGallery);
}

/**
 * Initialises a new PhotoSwipe Gallery for 360 view
 *
 * @param {HTMLElement} button
 * @param {Array} items
 */
export async function open360(button, items) {
    const gallery = await openPhotoSwipe(button, build360Items(items), {
        zoomEl: false,
        maxSpreadZoom: 1,
        closeOnVerticalDrag: false,
        preventSwiping: true,
    });

    if (!gallery) {
        console.error('Failed to open 360. PhotoSwipe gallery not created'); // eslint-disable-line no-console
        return;
    }

    lockViewport();
    gallery.listen('close', () => {
        unlockViewport();
    });

    handleActive360(gallery);
}

/**
 * Initialises a new PhotoSwipe Gallery for video
 *
 * @param {HTMLElement} button
 * @param {string} videoId
 */
export async function openVideo(button, videoId) {
    const accountId = '4223003854001';
    const playerId = '7db83734-5383-4884-9d75-b5b3f92243a8_default';
    const uri = `https://players.brightcove.net/${accountId}/${playerId}/index.html`;
    const query = `videoId=${videoId}&secureConnections=true&secureHTMLConnections=true&autoStart=true`;
    const items = [
        {
            html: `<iframe
                       class="gallery-launchers__video"
                       src="${uri}?${query}"
                       allowfullscreen=""
                       webkitallowfullscreen=""
                       mozallowfullscreen="">
                    </iframe>`,
        },
    ];

    await openPhotoSwipe(button, items, { zoomEl: false, maxSpreadZoom: 1 });
}

/**
 * Initialises a new PhotoSwipe Gallery for YouTube video
 *
 * @param {HTMLElement} button
 * @param {string} videoId
 */
export async function openYouTubeVideo(button, videoId) {
    const uri = `https://www.youtube.com/embed/${videoId}?rel=0`;
    const items = [
        {
            html: `<iframe
                        class="gallery-launchers__video gallery-launchers__video--youtube"
                        src="${uri}"
                        allowfullscreen=""
                        webkitallowfullscreen=""
                        mozallowfullscreen="">
                    </iframe>`,
        },
    ];

    const videoPopup = await openPhotoSwipe(button, items, { zoomEl: false, maxSpreadZoom: 1 });

    videoPopup.listen('close', () => {
        const youTubeIframe = document.querySelectorAll('.gallery-launchers__video--youtube');

        [...youTubeIframe].forEach((element) => {
            element.src = '';
        });
    });
}
