<template>
    <div :class="['inputs', { 'inputs--error' : hasError, 'password' : isPassword }]">
        <label
            :for="id"
            class="label"
        >
            {{ label }}
            <span
                v-if="required"
                class="label--required"
            >
                *
            </span>
        </label>
        <span
            v-if="isPassword"
            :class="['password__toggle', {'is-plain-text' : showPassword}]"
            @click="showPassword = !showPassword"
        />
        <input
            :id="id"
            :ref="name"
            v-at="testSelector"
            :form-id="formId"
            class="input"
            :placeholder="placeholder"
            :type="showPassword ? 'text' : type"
            :value="value"
            :name="name"
            @input="update"
            @blur="update"
            @change="update"
        >
        <p
            v-if="hint"
            class="text--v3 label__hint"
        >
            {{ hint }}
        </p>
        <span
            v-show="hasError"
            v-at="`error-${testSelector}`"
            class="error-message form__text"
        >
            {{ errorMessage }}
        </span>
    </div>
</template>

<script>
import InputBase from '~@common/form/InputBase';

export default {
    name: 'Input',
    extends: InputBase,
    data() {
        return {
            showPassword: false,
        };
    },
    computed: {
        isPassword() {
            return this.type === 'password';
        },
    },
};
</script>

<style scoped>
.error-message {
    display: block;
    margin-top: 8px;
}
</style>
