/**
 *
 * @param {HTMLElement} elem
 */
export default function loaderButton(elem) {
    elem.addEventListener('click', () => {
        if (elem.classList.contains('is-active')) {
            return;
        }

        elem.classList.add('is-active');

        window.addEventListener('pageshow', (e) => {
            if (e.persisted) {
                elem.classList.remove('is-active');
            }
        });
    });
}
