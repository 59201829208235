import { setCookie } from '~@helpers/cookie';
import tracking from '~@tracking/cart-reminder';
import loaderButton from '~@componentJs/loader-button';

const cartReminder = document.querySelector('.i-cart-reminder');

if (cartReminder) {
    const closeBtn = cartReminder.querySelector('.i-dismiss');

    closeBtn.addEventListener('click', () => {
        setCookie('cart-reminder-dismissed', { value: true });
        tracking.dismissed();
    });

    const actions = [...cartReminder.querySelectorAll('.i-track-cart-reminder-cta')];

    actions.forEach((elem) => {
        elem.addEventListener('click', () => {
            const label = elem.dataset.trackingLabel;

            if (label) {
                tracking.cta(label);
            }
        });
    });

    const loaders = [...cartReminder.querySelectorAll('.i-loader-button')];
    loaders.forEach(loaderButton);
}
