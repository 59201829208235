<template>
    <div
        :class="`input-qty--${size}`"
        class="input-qty"
    >
        <span
            v-track.click="'quantity-increment'"
            v-at="'quantity-down'"
            :class="{'disabled' : isDecrementDisabled}"
            class="input-qty__qty-adjust input-qty__qty-minus"
            @click="decrement"
        >-</span>
        <input
            :id="id"
            ref="input"
            v-model="qty"
            v-track.change="'quantity-changed'"
            v-at="testSelector"
            :min="min"
            :max="max"
            class="input-qty__qty"
            type="number"
            name="qty"
            :step="step"
            @change="validateInput"
        >
        <span
            v-track.click="'quantity-decrement'"
            v-at="'quantity-up'"
            :class="{'disabled' : isIncrementDisabled}"
            class="input-qty__qty-adjust input-qty__qty-plus"
            @click="increment"
        >+</span>
    </div>
</template>

<script>
export default {
    name: 'QuantityInput',
    props: {
        id: {
            type: String,
            default: '',
            required: false,
        },
        quantity: {
            type: Number,
            default: 1,
            required: false,
        },
        min: {
            type: Number,
            default: 1,
            required: false,
        },
        max: {
            type: Number,
            default: 500,
            required: false,
        },
        step: {
            type: Number,
            required: false,
            default: () => 1,
        },
        size: {
            type: String,
            required: false,
            default: 'md',
        },
        testSelector: {
            type: String,
            required: false,
            default: 'quantity-input',
        },
    },
    data() {
        return { qty: this.validateQuantity(this.quantity) };
    },
    computed: {
        isDecrementDisabled() {
            return this.qty <= this.min;
        },
        isIncrementDisabled() {
            return this.qty >= this.max;
        },
    },
    watch: {
        quantity() {
            this.qty = this.validateQuantity(this.quantity);
        },
    },
    methods: {
        validateQuantity(qty) {
            if (qty < this.min) {
                return this.min;
            }

            if (qty > this.max) {
                return this.max;
            }

            return Number(Math.round(qty));
        },
        increment() {
            this.qty = this.validateQuantity(this.qty + 1);
            this.$emit('updateQuantity', this.qty);
        },
        decrement() {
            this.qty = this.validateQuantity(this.qty - 1);
            this.$emit('updateQuantity', this.qty);
        },
        validateInput() {
            this.qty = this.validateQuantity(this.qty);
            this.$emit('updateQuantity', Number(this.qty));
        },
    },
};
</script>
