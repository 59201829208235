import vendorPrefix from '~@helpers/vendor-prefix';

const animationEndEvent = vendorPrefix.animationEndEvent();
/**
 * Allows an elements state to be dismissed
 */
export default class DismissibleItem {
    /**
     *
     * @param {HTMLElement} DOMElement
     */
    constructor(DOMElement) {
        if (!(DOMElement instanceof HTMLElement)) {
            throw new Error('DOMElement must be an instance of HTMLElement');
        }

        this.dismissibleItem = DOMElement;
        this.dismisser = this.dismissibleItem.querySelector('.i-dismiss');
        this.animationName = this.dismissibleItem.dataset.animationName ?? 'fade-out';
        this.bindDismiss();
    }

    bindDismiss() {
        this.dismisser.addEventListener('click', this.dismiss.bind(this));
    }

    willFadeOut() {
        return this.dismissibleItem.classList.contains('i-fade-out');
    }

    remove() {
        this.dismissibleItem.parentNode.removeChild(this.dismissibleItem);
    }

    dismiss() {
        if (this.willFadeOut()) {
            this.dismissibleItem.addEventListener(animationEndEvent, function fade(ev) {
                if (ev.animationName === this.animationName) {
                    this.dismissibleItem.removeEventListener(animationEndEvent, fade);
                    this.remove();
                }
            }.bind(this));

            this.dismissibleItem.classList.add('dismissed');
        } else {
            this.remove();
        }
    }
}

[...document.querySelectorAll('.i-dismissible')].forEach((item) => { new DismissibleItem(item); });
