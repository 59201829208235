import { lockViewport, unlockViewport } from '~@helpers/viewport'; /* eslint-disable-line */
import overlayMediator from '~@helpers/overlay-mediator';
import passiveIfSupported from '~@helpers/passive-supported';
import Backdrop from './backdrop';

/**
 * Look for any 'close' buttons, and wire them up
 *
 * @param {HTMLElement} element
 * @param {OffCanvasPanel} panel
 */
function closeBtns(element, panel) {
    const btns = element.querySelectorAll('.i-close-off-canvas-panel');

    if (btns.length > 0) {
        [...btns].forEach((btn) => {
            btn.addEventListener('click', () => {
                panel.close(btn);
            });
        });
    }
}

/**
 * Hide vertical scrollbars
 *
 * @param {HTMLElement} element
 */
function hideScrollbars(element) {
    if (element) {
        const offset = element.offsetWidth - element.clientWidth;

        if (offset > 0) {
            element.style.width = `calc(100% + ${offset}px)`;
        }
    }
}

/**
 * "Try" to counter IOS overscroll.
 *
 * @param {HTMLElement} element
 */
function removeIOSOverflow(element) {
    if (element) {
        element.addEventListener('touchstart', () => {
            const top = element.scrollTop;
            const totalScroll = element.scrollHeight;
            const currentScroll = top + element.offsetHeight;

            if (top === 0) {
                element.scrollTop = 1;
            } else if (currentScroll === totalScroll) {
                element.scrollTop = top - 1;
            }
        }, passiveIfSupported);
    }
}

/**
 * Off Canvas Panel {class}
 */
class OffCanvasPanel {
    /**
     * @param {HTMLElement} element
     */
    constructor(element) {
        this.element = element;
        this.id = element.id;
        this.isOpen = false;
        closeBtns(element, this);
        this.actions = { before: [], after: [] };
        this.moduleId = 'off-canvas-panel';

        const scollingElement = element.querySelector('.off-canvas-panel__inner');
        if (scollingElement) {
            hideScrollbars(scollingElement);
            removeIOSOverflow(scollingElement);
        }
    }

    /**
     * User functions to execute before opening the panel
     *
     * @param {Function} fn
     */
    onBeforeOpen(fn) {
        this.actions.before.push(fn);
    }

    /**
     * User functions to execute after closing the panel
     *
     * @param {Function} fn
     */
    onAfterClose(fn) {
        this.actions.after.push(fn);
    }

    /**
     * @returns {boolean}
     */
    get active() {
        return this.isOpen;
    }

    /**
     * @param {HTMLElement} element
     * @returns {OffCanvasPanel}
     */
    open(element) {
        overlayMediator.register(this.moduleId, this.close.bind(this));
        this.actions.before.forEach(fn => fn(element));
        this.element.classList.add('is-active');
        Backdrop.show({ fullscreen: true });
        lockViewport();
        this.isOpen = true;
        return this;
    }

    /**
     * @param {HTMLElement} element
     * @returns {OffCanvasPanel}
     */
    close(element) {
        overlayMediator.unregister(this.moduleId);
        this.element.classList.remove('is-active');
        Backdrop.hide();
        unlockViewport();
        this.isOpen = false;
        this.actions.after.forEach(fn => fn(element));
        return this;
    }

    /**
     * Add a trigger to toggle the panel
     *
     * @param {HTMLElement} element
     */
    addTrigger(element) {
        if (element) {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggle(element);
            });
        }
    }

    /**
     * @param {HTMLElement} element
     * @returns {OffCanvasPanel}
     */
    toggle(element) {
        if (this.isOpen) {
            return this.close(element);
        }

        return this.open(element);
    }
}

class OffCanvasPanels {
    constructor() {
        this.panels = [...document.querySelectorAll('.i-off-canvas-panel')].map(panel => new OffCanvasPanel(panel));
    }

    byId(id) {
        return this.panels.find(panel => panel.id === id);
    }
}

const offCanvasPanels = new OffCanvasPanels();
export default offCanvasPanels;
