const browser = window.victoriaplum.browser || {};
const userAgent = window.navigator.userAgent || window.navigator.vendor;

const Browser = {
    isMobile() {
        return browser.isMobile;
    },
    isTablet() {
        return browser.isTablet;
    },
    isAndroid() {
        return /android/i.test(userAgent);
    },
};

export default Browser;
