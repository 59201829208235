import popper from '~@componentJs/popper';
import Backdrop from '~@componentJs/backdrop';

const helpServiceMenuElem = document.querySelector('.i-help-service-menu');

if (helpServiceMenuElem) {
    const helpServiceContent = helpServiceMenuElem.querySelector('.i-help-service-content');
    const helpServiceMenu = popper(helpServiceMenuElem);

    let mouseIn;

    helpServiceMenuElem.addEventListener('mouseenter', (event) => {
        event.preventDefault();

        mouseIn = true;

        if (!helpServiceMenu.active) {
            setTimeout(() => {
                if (mouseIn) {
                    helpServiceMenu.setActive();
                    helpServiceContent.focus();
                    Backdrop.show();
                }
            }, 250);
        }
    });

    helpServiceMenuElem.addEventListener('mouseleave', (event) => {
        event.preventDefault();

        mouseIn = false;

        const isInsideMenu = helpServiceMenuElem.contains(event.relatedTarget);

        if (isInsideMenu) {
            return;
        }

        if (helpServiceMenu.active) {
            setTimeout(() => {
                if (!mouseIn) {
                    helpServiceMenu.setInactive();
                }
            }, 250);
        }
    });

    [...document.querySelectorAll('.i-trigger-help-service')].forEach((elem) => {
        elem.addEventListener('click', (event) => {
            event.preventDefault();
        });

        elem.addEventListener('touchend', (event) => {
            event.preventDefault();

            if (helpServiceMenu.active) {
                helpServiceMenu.setInactive();
                return;
            }

            helpServiceMenu.setActive();
            helpServiceContent.focus();
            Backdrop.show();
        });

        elem.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                helpServiceMenu.setActive();
                helpServiceContent.focus();
            }
        });
    });

    [...document.querySelectorAll('.i-close-help-service')].forEach((elem) => {
        elem.addEventListener('click', (event) => {
            event.preventDefault();

            if (helpServiceMenu.active) {
                helpServiceMenu.setInactive();
            }
        });
    });
}
