export default class ActivatableCollection {
    constructor() {
        this.items = [];
    }

    addItem(item) {
        this.items.push(item);
    }

    /**
     * @returns {Array}
     */
    active() {
        return this.items.filter(item => item.active);
    }

    /**
     * @returns {Array}
     */
    all() {
        return this.items;
    }

    /**
     * @returns {number}
     */
    count() {
        return this.items.length;
    }

    /**
     * @param {number} index
     * @returns {[*]} item
     */
    item(index) {
        return this.items[index];
    }
}
