import pluralise from '~@helpers/pluralise';

const divisor = {
    days: 86400000,
    hours: 3600000,
    minutes: 60000,
    seconds: 1000,
};

const modulus = {
    hours: 24,
    minutes: 60,
    seconds: 60,
};

/**
 * @param { number } milliseconds
 * @param {string} type
 * @returns {number}
 */
function getTimeSegment(milliseconds, type) {
    try {
        const time = Math.floor((milliseconds / divisor[type]) % modulus[type]);
        return time > 0 ? time : 0;
    } catch (e) {
        return 0;
    }
}

/**
 * @param { number } milliseconds
 * @returns {number}
 */
export function getDays(milliseconds) {
    const days = Math.floor((milliseconds / divisor.days));
    return days > 0 ? days : 0;
}

/**
 * @param { number } milliseconds
 * @returns {number}
 */
export function getHours(milliseconds) {
    return getTimeSegment(milliseconds, 'hours');
}

/**
 * @param { number } milliseconds
 * @returns {number}
 */
export function getMinutes(milliseconds) {
    return getTimeSegment(milliseconds, 'minutes');
}

/**
 * @param { number } milliseconds
 * @returns {number}
 */
export function getSeconds(milliseconds) {
    return getTimeSegment(milliseconds, 'seconds');
}

/**
 * @param { number } milliseconds
 * @returns {number}
 */
export function getTimeRemaining(milliseconds) {
    return {
        days: getDays(milliseconds).toString(),
        hours: getHours(milliseconds).toString(),
        minutes: getMinutes(milliseconds).toString().padStart(2, '0'),
        seconds: getSeconds(milliseconds).toString().padStart(2, '0'),
    };
}

/**
 * @param { number } year
 * @param { number } month
 * @returns {Date}
 */
export function daysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
}

/**
 * @param { number } year
 * @returns {number}
 */
export function daysInYear(year) {
    return ((year % 4 === 0 && year % 100 > 0) || year % 400 === 0) ? 366 : 365;
}

/**
 * @param { number } result
 * @param { string } unit
 * @returns {string}
 */
function formatResult(result, unit) {
    return `${ result } ${ pluralise(result, unit) } ago`;
}

/**
 * @param { number } timestamp
 * @returns {string}
 */
export function timeAgo(timestamp) {
    let result = 0;
    const dateFromTimestamp = new Date(timestamp * 1000);
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerWeek = msPerDay * 7;
    const msPerMonth = msPerDay * daysInMonth(dateFromTimestamp.getFullYear(), dateFromTimestamp.getMonth() + 1);
    const msPerYear = msPerDay * daysInYear(dateFromTimestamp.getFullYear());

    const elapsed = new Date() - dateFromTimestamp;

    if (elapsed < msPerMinute) {
        result = Math.round(elapsed / 1000);
        return formatResult(result, 'second');
    }

    if (elapsed < msPerHour) {
        result = Math.round(elapsed / msPerMinute);
        return formatResult(result, 'minute');
    }

    if (elapsed < msPerDay) {
        result = Math.floor(elapsed / msPerHour);
        return formatResult(result, 'hour');
    }

    if (elapsed < msPerMonth) {
        result = (Math.ceil(elapsed / msPerDay) - 1);

        if (result > 7) {
            result = (Math.round(elapsed / msPerWeek));
            return formatResult(result, 'week');
        }

        return formatResult(result, 'day');
    }

    if (elapsed < msPerYear) {
        // NEW Month compare logic due to Epoch Timestamp issues
        const date1 = dateFromTimestamp;
        const date2 = new Date();
        const diffYears = date2.getFullYear() - date1.getFullYear();
        const diffMonths = date2.getMonth() - date1.getMonth();
        const diffDays = date2.getDate() - date1.getDate();

        let months = (diffYears * 12 + diffMonths);
        if (diffDays > 0) {
            months += `.${diffDays}`;
        } else if (diffDays < 0) {
            months -= 1;
            months += `.${new Date(date2.getFullYear(), date2.getMonth(), 0).getDate() + diffDays}`;
        }

        result = Math.floor(months);

        return formatResult(result, 'month');
    }

    result = (Math.floor(elapsed / msPerYear));
    if (result === 0) { result = 1; }

    return formatResult(result, 'year');
}
