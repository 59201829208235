import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';

export default {
    computed: {
        checkoutScrollOffset() {
            return (window.matchMedia('(max-width: 1023px)').matches) ? -131 : 0;
        },
        returninatorScrollOffset() {
            return (window.matchMedia('(max-width: 1023px)').matches) ? 0 : -48;
        },
        navigationOffset() {
            return (window.matchMedia('(max-width: 1023px)').matches) ? 0 : -48;
        },
    },
    methods: {
        scrollToElement(selector, offset = 0, duration = 600) {
            const options = {
                easing: 'ease-in-out',
                offset,
                duration,
            };

            if (window.victoriaplum.env.test) {
                Vue.nextTick(() => {
                    document.querySelector(selector).scrollIntoView({ block: 'start' });
                });
            } else {
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        VueScrollTo.scrollTo(selector, duration, options);
                    });
                });
            }
        },
    },
};
