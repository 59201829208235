import ActivatableCollection from '~@componentJs/activatable-collection';
import ActivatableItem from '~@componentJs/activatable-item';

/**
 *
 * @param {HTMLElement} section
 * @returns {ActivatableCollection}
 */
function createCollection(section) {
    const collection = new ActivatableCollection();
    [...section.querySelectorAll('.i-cycle')].forEach(elem => collection.addItem(new ActivatableItem(elem)));

    return collection;
}
/**
 * Cycle over child elements setting them active on animationend
 * Uses CSS animations to control the speed and behaviour of the cycle
 *
 * @param {HTMLElement} section
 */
export default function cyclable(section) {
    const collection = createCollection(section);
    const count = collection.all().length - 1;
    let iteration = 0;

    section.addEventListener('animationend', (ev) => {
        ev.stopImmediatePropagation();

        if (ev.animationName !== 'cycle-element' || ev.target !== collection.active()[0].element) {
            return;
        }

        iteration = (iteration >= count) ? 0 : iteration += 1;
        collection.active()[0].setInactive();
        collection.all()[iteration].setActive();
    });

    collection.all()[iteration].setActive();
}

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.i-will-cycle')].forEach(cyclable);
});
