import Vue from 'vue';
import PwaInstall from '../../../vue/component/pwa/PwaInstall';

/**
 * @param {Event} ev
 * @param {Function} callback
 */
export default function installPwa(ev, callback) {
    const mountPoint = document.querySelector('.i-pwa-install');

    if (mountPoint) {
        new Vue({
            el: mountPoint,
            components: {
                PwaInstall,
            },
            data() {
                return {
                    ev,
                    callback,
                };
            },
            template: '<PwaInstall :evt="ev" @close="callback"/>',
        });
    }
}
