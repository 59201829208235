const bundles = {
    photoswipe: () => import(/* webpackChunkName: 'photoswipe-dynamic' */ 'photoswipe'),
    'photoswipe-ui': () => import(/* webpackChunkName: 'photoswipe-ui-dynamic' */ 'photoswipe/dist/photoswipe-ui-default'),
    lazysizes: () => import(/* webpackChunkName: 'lazy-sizes-dynamic' */ 'lazysizes'),
    picturefill: () => import(/* webpackChunkName: 'picturefill-dynamic' */ 'picturefill'),
    kount: () => import(/* webpackChunkName: 'kount-dynamic' */ '@kount/kount-web-client-sdk'),
    stockMessage: () => import(
        /* webpackChunkName: "stock-message-dynamic" */
        '../../../vue/component/product-detail/StockMessage'
    ),
    bundleItems: () => import(
        /* webpackChunkName: "bundle-items-dynamic" */
        '../../../vue/component/product-detail/BundleItems'
    ),
    productSpecifications: () => import(
        /* webpackChunkName: "product-specifications-dynamic" */
        '../../../vue/component/product-detail/panels/specifications/ProductSpecifications'
    ),
    productInstructions: () => import(
        /* webpackChunkName: "product-instructions-dynamic" */
        '../../../vue/component/product-detail/panels/instructions/ProductInstructions'
    ),
    productReviews: () => import(
        /* webpackChunkName: "product-reviews-dynamic" */
        '../../../vue/component/product-detail/panels/reviews/ProductReviews'
    ),
    productQuestions: () => import(
        /* webpackChunkName: "product-questions-dynamic" */
        '../../../vue/component/product-detail/panels/questions/ProductQuestions'
    ),
    productDescription: () => import(
        /* webpackChunkName: "product-description-dynamic" */
        '../../../vue/component/product-detail/panels/description/ProductDescription'
    ),
    youWillNeed: () => import(
        /* webpackChunkName: "ywn-product-page-dynamic" */
        '../../../vue/component/product-detail/panels/you-will-need/YouWillNeed'
    ),
    productDelivery: () => import(
        /* webpackChunkName: "product-delivery-dynamic" */
        '../../../vue/component/product-detail/panels/delivery/ProductDelivery'
    ),
    productReturns: () => import(
        /* webpackChunkName: "product-returns-dynamic" */
        '../../../vue/component/product-detail/panels/returns/ProductReturns'
    ),
    alsoLike: () => import(
        /* webpackChunkName: "also-like-dynamic" */
        '../../../vue/component/product-detail/AlsoLike'
    ),
    relatedArticles: () => import(
        /* webpackChunkName: "related-articles-dynamic" */
        '../../../vue/component/product-detail/RelatedArticles'
    ),
    productDetail: () => import(
        /* webpackChunkName: "product-detail-dynamic" */
        '../../../vue/component/product-detail/ProductDetail'
    ),
    coverage: () => import(
        /* webpackChunkName: "coverage-dynamic" */
        '../../../vue/component/product-detail/Coverage'
    ),
    gallery: () => import(
        /* webpackChunkName: "galleryLaunchers-dynamic" */
        '../../../vue/component/product-detail/gallery/Gallery'
    ),
    stickyCta: () => import(
        /* webpackChunkName: "stickyCta-dynamic" */
        '../../../vue/component/product-detail/StickyCta'
    ),
    payPalMessage: () => import(
        /* webpackChunkName: "paypal-message-dynamic" */
        '../../../vue/component/checkout/payment/gateway/paypal/PayPalMessage'
    ),
    productCode: () => import(
        /* webpackChunkName: "product-code-dynamic" */
        '../../../vue/component/product-detail/ProductCode'
    ),
};

/**
 *
 * @param {string} bundle
 * @returns {null|*}
 */
export default function loadBundle(bundle) {
    if (({}).hasOwnProperty.call(bundles, bundle)) {
        return bundles[bundle]();
    }

    throw new Error(`No bundle available for ${bundle}`);
}
