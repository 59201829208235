window.addEventListener('DOMContentLoaded', () => {
    const openingTimes = document.querySelector('.i-opening-times');
    const customerService = window.victoriaplum.cs;

    if (!openingTimes || !customerService) {
        return;
    }

    if (!customerService.callCentreIsOpenToday || !customerService.phoneNumbersEnabled) {
        openingTimes.innerText = 'Closed today';
    } else {
        openingTimes.innerText = `Open ${customerService.callCentreTodayHours}`;
    }

    openingTimes.classList.remove('is-invisible');
});
