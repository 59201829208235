<template>
    <button
        :disabled="isLoading || isDisabled"
        :type="type"
        class="btn"
        @click="$emit('onClick')"
    >
        <div
            v-if="isLoading"
            class="loading"
        >
            <Loader
                :size="loaderSize"
                :theme="loaderTheme"
            />
        </div>
        <slot v-else />
    </button>
</template>

<script>
import Loader from '../Loader';

export default {
    name: 'Button',
    components: {
        Loader,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        loaderSize: {
            type: String,
            default: '',
        },
        loaderTheme: {
            type: String,
            default: '',
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
