export default class {
    constructor(panel) {
        this.panel = panel;
        this.mappedEvents = new Map([
            ['open', new Map()],
            ['close', new Map()],
        ]);

        this.panel.onAfterClose(this.triggerEvent.bind(this, 'close'));
        this.panel.onBeforeOpen(this.triggerEvent.bind(this, 'open'));
    }

    add(action, name, fnc) {
        if (this.mappedEvents.has(action)) {
            this.mappedEvents.get(action).set(name, fnc);
        }
    }

    activePanelView() {
        return this.panel.element.querySelector('.i-panel-view.is-active');
    }

    context() {
        let view = this.activePanelView();

        if (!view) {
            return '';
        }

        const tab = view.querySelector('.i-tab-body.is-active');

        view = tab || view;

        return view.getAttribute('data-name');
    }

    get events() {
        return this.mappedEvents;
    }

    triggerEvent(action, ...args) {
        const mappedEvent = this.mappedEvents.get(action).get(this.context());
        if (mappedEvent) {
            mappedEvent(args);
        }
    }
}
