<template>
    <form
        id="search-form"
        ref="form"
        v-at="'search-form'"
        :class="['searchbar', { 'hide-icon': searchSubmitting }]"
        action="/search"
        method="GET"
        @submit.prevent="searchSubmit"
        @keydown.enter.prevent="searchSubmit"
    >
        <div
            v-at="'searchbar-wrapper'"
            class="search__wrapper"
        >
            <label
                id="searchbar-label"
                v-at="'search-bar-label'"
                :class="{'active': hidePlaceholder}"
                class="searchbar__label"
                for="searchbar"
            >
                <input
                    id="searchbar"
                    v-at="'search-bar-input'"
                    :value="query"
                    aria-labelledby="searchbar-label"
                    type="text"
                    name="query"
                    autocomplete="off"
                    class="input searchbar__input i-quicksearch-input"
                    data-tray-id="search-tray"
                    @focus="searchHasFocus()"
                    @blur="searchBlur"
                    @input="val => query = val.target.value"
                >
                <span class="searchbar__placeholder">
                    Search for products<span class="searchbar__placeholder--desktop">, brands and inspiration</span>
                </span>
            </label>

            <button
                id="header-search-button"
                v-at="'search-bar-icon'"
                :disabled="searchFieldIsEmpty"
                type="submit"
                class="searchbar__submit"
                aria-label="Search"
            >
                <div
                    v-if="searchSubmitting"
                    id="search-loader"
                    class="loading"
                >
                    <Loader theme="dark" />
                </div>
            </button>
        </div>
    </form>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import debounce from 'lodash/debounce';
import Loader from '~@common/Loader';
import CustomEventHelper from '~@helpers/custom-event';
import quicksearchTracking from '~@tracking/search';

export default {
    name: 'Searchbar',
    components: {
        Loader,
    },
    data() {
        return {
            searchSubmitting: false,
            query: '',
        };
    },
    computed: {
        ...mapState({
            searchFocus: 'searchFocus',
        }),
        searchFieldIsEmpty() {
            return this.query.length === 0;
        },
        hidePlaceholder() {
            return this.searchFocus || !this.searchFieldIsEmpty;
        },
    },
    watch: {
        query(val) {
            if (val) {
                this.debounceSearch();
            }
        },
    },
    methods: {
        ...mapActions({
            setSearchQuery: 'setSearchQuery',
            setSearchFocus: 'setSearchFocus',
        }),
        debounceSearch: debounce(function searchDebounce() {
            this.setSearchQuery(this.query);
        }, 500),
        searchHasFocus() {
            this.setSearchFocus(true);
            window.dispatchEvent(CustomEventHelper('mount-search'));
        },
        searchBlur() {
            if (this.query === '') {
                this.setSearchFocus(false);
            }
        },
        searchSubmit() {
            if (this.searchFieldIsEmpty) {
                return;
            }

            this.searchSubmitting = true;
            quicksearchTracking.trackQuery(this.query);

            // The form submit event is prevented initially and then manually
            // triggered after an arbitrary timeout to get around an issue on iOS and MacOS.
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.form.submit();
                }, 250);
            });
        },
    },
};

</script>

<style scoped>
    .searchbar__submit >>> svg {
        fill: var(--purple-100);
    }

    .loading >>> svg {
        width: 18px;
        height: 18px;
        stroke-width: 3px;
    }
</style>
