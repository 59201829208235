/**
 * A global listener to remove 'no-focus-outline' from the root
 * element when users are tabbing around the site.
 *
 * https://jmperezperez.com/outline-focus-ring-a11y/
 */
// Listen to tab events to enable outlines (accessibility improvement)
document.addEventListener('keyup', enableAccessibilityOutline);

/**
 * @param {Event} e
 */
function enableAccessibilityOutline(e) {
    if (e.key === 'Tab') {
        document.body.classList.remove('no-focus-outline');
        document.removeEventListener('keyup', enableAccessibilityOutline);
    }
}
