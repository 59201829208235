import axios from 'axios';

/**
 * Axios automatically handles sending the CSRF token
 * as a header based on the value of the "XSRF" token cookie.
 */

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached.
 * This negates the need to send the csrf token with every XHR request.
 */

const token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}

const host = window.location.hostname;

if (host === 'victoriaplum.com') {
    axios.defaults.baseURL = 'https://victoriaplum.com';
}

axios.interceptors.response.use(response => response, error => Promise.reject(error));
