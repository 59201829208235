/**
 * @param {object} url
 * @param {object} options
 * @returns {string}
 */
function generateUrlTemplate(url, options = {}) {
    const { searchParams } = url;
    const base = `${url.origin}${url.pathname}`;

    searchParams.delete('w');
    searchParams.delete('h');

    Object.entries(options).forEach(([k, v]) => {
        searchParams.set(k, v);
    });

    return `${base}?${searchParams.toString()}`;
}

/**
 * @param {string} value
 * @returns {string}
 */
function padFilePart(value) {
    return `${value}`.padStart(2, '0');
}

/**
 * @param {string} uri
 * @param {number} rows
 * @param {number} columns
 * @param {number} width
 * @param {number} height
 *
 * @returns {Array}
 */
function buildImages(uri, rows, columns, width, height) {
    const images = [];
    const url = new URL(uri);
    const basename = url.pathname.split('/').pop().split('.')[0];
    const options = { auto: 'format' };

    if (width >= height) {
        options.h = height;
    } else {
        options.w = width;
    }

    const urlTemplate = generateUrlTemplate(url, options);

    for (let row = 1; row <= rows; row += 1) {
        for (let col = 1; col <= columns; col += 1) {
            const filename = `${padFilePart(row)}-${padFilePart(col)}`;
            images.push(urlTemplate.replace(`/${basename}.`, `/${filename}.`));
        }
    }

    return images;
}

export default class ImageLoader {
    /**
     * @param {string} uri
     * @param {number} rows
     * @param {number} columns
     * @param {number} width
     * @param {number} height
     */
    constructor(uri, rows, columns, width, height) {
        this.images = buildImages(uri, rows, columns, width, height);
    }

    /**
     * @returns {Promise.<*>}
     */
    load() {
        const promises = this.images.map(image => new Promise((resolve, reject) => {
            const img = new Image();
            img.addEventListener('load', resolve);
            img.addEventListener('error', reject);
            img.src = image;
        }));

        return promises;
    }
}
